import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Url, EventsEndpoint, EventsDetailEndpoint } from "../../Type/BaseUrl";

const EventServices = createApi({
	reducerPath: "EventServices",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
		prepareHeaders: (headers, { getState }) => {
			headers.set("Access-Control-Allow-Origin", "*");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			eventsContent: builder.query({
				query: () => {
					return {
						url: EventsEndpoint,
						method: "GET",
					};
				},
			}),
			eventsContentGet: builder.query({
				query: (slug) => {
					return {
						url: `${EventsDetailEndpoint}/${slug}`,
						method: "GET",
					};
				},
			}),
		};
	},
});

export const { useEventsContentQuery, useEventsContentGetQuery } =
	EventServices;
export default EventServices;
