export const Url = "https://pipeparadise.developer-ourbase-camp.com/api/v1";
export const LoginEndpoint = "/auth/login";
export const RegisterEndpoint = "/register/login";
export const SettingEndpoint = "/sitesetting";
export const HomeAboutEndpoint = "/home_about";
export const GalleryEndPoint = "/gallery";
export const FaqEndPoint = "/faq";
export const ImagesUrlEndPoint = "/config/urls";
export const NewsletterEndpoint = "/newsletter_subscribe";
export const ContactEndpoint = "/contact_query";
export const MembershipEndpoint = "/membership/all";
export const SocialEndpoint = "/social-media";
export const LocationEndpoint = "/locations";
export const LoungeEndpoint = "/lounge_rental";
export const ConfigEndpoint = "/config/web-config";
export const EventsEndpoint = "/events";
export const EventsDetailEndpoint = "/events/show";
export const CouponCodeEndpoint = "/coupon/apply";
export const PlaceOrderEndpoint = "/customer/order/placeorder";
export const OrderListEndpoint = "/customer/order/list";
export const GetMembershipEndpoint = "/customer/membership/current-membership";
export const CancelMembershipEndpoint =
	"/customer/membership/cancel-membership";
export const MembershipPlaceOrderEndpoint =
	"/customer/membership/book-membership";
export const BannerEndpoint = "/banners?banner_type=all";
