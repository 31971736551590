import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import bannervideo1 from "../../assets/img/bannervideo1.mp4";
import Header from "../../components/Header";

import {
	arrow,
	homeabout,
	abouticon,
	hand,
	blackflower,
	shop1,
	arrow2,
	grass,
	banner1,
	banner2,
	banner3,
	homevideo,
	shop2,
	shop3,
	shop4,
	shop5,
	shop6,
	dollar,
	sidemove,
	websiteLogo,
	logo,
} from "../../constant/Index";
import Threelinks from "../../components/Threelinks";
import { useAboutContentQuery } from "../../store/services/HomeAboutService";
import Gallery from "../../components/Gallery";
import Newsletter from "../../components/Newsletter";
import { useImageContentQuery } from "../../store/services/ImagesUrlService";
import {
	useBannerContentQuery,
	useSettingContentQuery,
} from "../../store/services/SiteSettingService";
import Membership from "../../components/Membership";
import { useProductContentQuery } from "../../store/services/ProductService";
import Shop from "../Shop/Shop";
import currency from "currency-formatter";

function Home() {
	const navigate = useNavigate();
	const [play, setPlay] = useState(false);
	const handlePause = (e) => {
		e.preventDefault();
		setPlay(false);
	};
	const handlePlay = (e) => {
		e.preventDefault();
		setPlay(true);
	};

	// Banner Api Call
	const { data: bannerData, isLoading: bannerLoading } =
		useBannerContentQuery();
	let bannerContent = bannerData?.response?.data[0];
	// Banner Api Call

	// Config Urls Api Call
	const { data: urls, isLoading: urlLoading } = useImageContentQuery();
	let banner_video = urls?.response?.data?.banner_video_url;
	let about_image = urls?.response?.data?.home_about_us_image_url;
	// Config Urls Api Call

	// Sitesetting Api Call
	const { data: setting, isLoading: settingLoading } = useSettingContentQuery();
	let siteData = setting?.response?.data?.[0];
	// Sitesetting Api Call

	// Product Api Call
	const { data: productData, isLoading: productLoading } =
		useProductContentQuery();
	let products = productData?.response?.data?.products;
	// Product Api Call

	// HomeAbout Api Call
	const { data, isLoading } = useAboutContentQuery();
	let aboutContent = data?.response?.data[0];
	// HomeAbout Api Call

	const [catergoryId, setCategoryId] = useState(null);
	let Data = {
		category_id: catergoryId,
		featured: 1,
	};
	const { data: feturedProduct, isLoading: feturedLoading } =
		useProductContentQuery(Data);
	const feturedProductData = feturedProduct?.response?.data?.products;

	const HandleCategory = (id) => {
		setCategoryId(id);
	};

	const HandleClick = (id) => {
		navigate(`/shop-detail/${id}`);
	};

	return (
		<>
			<Header />

			{/* Banner-section */}
			<section className="banner-sec">
				<div className="background-video">
					<video loop muted autoPlay={"autoplay"}>
						<source src={bannervideo1} />
					</video>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-md-5">
							<div className="banner-text">
								<h5>
									{bannerContent?.sub_title}
									{/* SMOKE <span className="for-gap-in-words">PIPE</span> EVERYDAY */}
								</h5>
								<h2>
									{bannerContent?.title}
									<span>.</span>
								</h2>
								{/* <h4>Open 7 Days A Week • Walk-Ins Welcome • Private Lounge</h4> */}

								<p>{bannerContent?.description}</p>
								<div className="banner-button only-for-banner-btn">
									<Link to="/shop" className="all-button">
										SHOP NOW <img className="img-fluid" src={arrow} />{" "}
									</Link>
								</div>
							</div>

							<div className="video-on-home">
								<div className="video-img">
									<img className="img-fluid" src={homevideo} />
									<div className="video-icon-dv">
										<div className="video-icon">
											<button
												className="modal-btn"
												data-bs-toggle="modal"
												data-bs-target="#VideoModal"
												onClick={handlePlay}
											>
												<div className="play-border">
													<i className="fa fa-play" aria-hidden="true"></i>
												</div>
											</button>
										</div>
									</div>
								</div>
								<div className="video-text">
									<h5>Open 7 days a week</h5>
									<p>
										We know the urgency and need for clean green, so we’re
										available every day for our customers.
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-3 for-hide"></div>
						<div className="col-md-4">
							<div className="row">
								<div className="col-md-12">
									{feturedProductData?.length > 0 &&
										feturedProductData?.map((item, index) => {
											return (
												index < 3 && (
													<div
														onClick={() => HandleClick(item?.slug)}
														className={`${
															(index + 1) % 2
																? "all-forum-img for-slightly-moving"
																: "all-forum-img"
														}`}
														key={index}
													>
														<div className="forum-img">
															<img
																className="img-fluid"
																src={item?.thumbnail}
															/>

															<div className="forum-text">
																<h3 title={item?.name} className="line-clamp1">
																	{item?.name}
																</h3>
																<h4>
																	USD{" "}
																	{currency.format(item?.unit_price, {
																		code: "USD",
																	})}
																</h4>
															</div>
															<div className="rating-start">
																<h4>
																	{item?.rating?.[0] === undefined
																		? "0.0"
																		: item?.rating?.[0]?.average?.slice(
																				0,
																				3,
																		  )}{" "}
																	<span>
																		<i
																			className="fa fa-star"
																			aria-hidden="true"
																		></i>
																	</span>
																</h4>
															</div>
														</div>
													</div>
												)
											);
										})}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="modal fade"
					id="VideoModal"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabindex="-1"
					aria-labelledby="VideoModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-body">
								<button
									onClick={handlePause}
									type="button"
									class="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
								></button>
								{siteData?.banner_video ? (
									<ReactPlayer
										width="100%"
										controls={true}
										playing={play}
										onPause={handlePause}
										url={`${banner_video}/${siteData?.banner_video}`}
									/>
								) : null}
							</div>
						</div>
					</div>
				</div>
				<Threelinks />
				<div className="side-move-pic">
					<img className="img-fluid marquee" src={sidemove} />
				</div>
			</section>
			{/* ============================================== */}

			{/* About section */}
			<section className="banner-about-sec about-main-home-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="about-img text-center">
								{/* <div className="about-icon-img">
									<div className="img-1">
										<img className="img-fluid" src={hand} />
										<h5>SMOKE LOUNGE</h5>
									</div>
								</div> */}
								<img
									className="img-fluid"
									src={about_image + "/" + aboutContent?.image}
								/>
								{/* <div className="about-icon-img2">
									<div className="img-2">
										<img className="img-fluid" src={abouticon} />
										<h5>CANABIS SMOKING</h5>
									</div>
								</div> */}
							</div>
						</div>

						<div className="col-md-6">
							<div className="about-banner-text banner-text home-about-banner-responsive">
								{/* <h5>{aboutContent?.sub_title}</h5>
								<h2>
									{aboutContent?.title}
									<span>.</span>
								</h2> */}

								{/* <p>{aboutContent?.description}</p> */}
								<p
									className="description"
									dangerouslySetInnerHTML={{ __html: siteData?.about_us }}
								></p>
								<div className="banner-button home-about-banner-btn">
									<Link to="/about-us" className="all-button">
										L E A R N <span className="for-gap-in-words">M O R E</span>
										<img className="img-fluid" src={arrow} />{" "}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About Section */}

			{/* Smoke shop section */}
			<section className="smoke-shop-section">
				<div className="grass-img">
					<img className="img-fluid" src={grass} />
				</div>
				<div className="container">
					<div className="about-banner-text shop-banner-text">
						<h5>
							Smoke <span className="for-gap-in-words">Pipe</span> Everyday
						</h5>
						<h2>
							Shop orderzaza<span>.</span>
						</h2>

						<p>
							The Orderzaza has gathered all your demands in one large
							collection of our top-quality products. Browse and take your pick
							to level up.
						</p>
					</div>

					<Shop
						paginationStatus="false"
						headers="false"
						headingData="false"
						footerData="false"
						setlength={6}
					/>
				</div>
			</section>
			{/* ================================================== */}

			{/* Become Member section  */}
			<section className="become-member-sec">
				<div className="container">
					<div className="about-banner-text become-member-head">
						<h5>Become Member</h5>
						<h2>
							become a member<span>.</span>
						</h2>
						<p>
							Experience to the maximum with our membership. Without further
							ado, be a part of our exclusive club.
						</p>
						<p>
							<span> Use the free membership while its available!</span>
						</p>
					</div>
					<div className="row">
						<Membership />
					</div>
				</div>
			</section>
			{/* ========================================================== */}

			{/* Spining section */}
			<section className="spin-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="spin-text">
								<div className="about-banner-text shop-banner-text spin-div">
									{/* <h5>
										SMOKE <span className="for-gap-in-words">PIPE</span>{" "}
										EVERYDAY
									</h5>
									<h2>Questions spinning in your mind?</h2>
									<p>
										We won’t make you wait for the answers, so drop us your
										thoughts, and we’ll get back to you.
									</p> */}
									<div
										dangerouslySetInnerHTML={{ __html: siteData?.contact_us }}
									></div>
									<div className="banner-button spin-btn">
										<Link to="/contact-us" className="all-button">
											CONTACT US
											<img className="img-fluid" src={arrow} />{" "}
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ========================================================== */}

			{/* Orderzaza Section */}
			<section className="order-zaza-sec">
				<div className="container">
					<div className="order-zaza-head">
						<h2>Order ZaZa</h2>
						<p>we use the delivery service Order ZaZa</p>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="order-zaza-all-stuff">
								<img alt="order-zaza-img" src={logo} />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Orderzaza Section */}

			{/* Gallery Section  */}
			<Gallery />
			{/* Gallery Section  */}

			{/* Newsletter Section  */}
			<Newsletter />
			{/* Newsletter Section */}

			<Footer />
		</>
	);
}

export default Home;
