import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Slide from "../../components/Slide";
import shop from "../../assets/css/shop.css";
import {
	arrow,
	arrow2,
	review1,
	review2,
	review3,
	shop1,
	shop2,
	shop3,
	shop4,
	shop5,
	shop6,
	dummyImage,
} from "../../constant/Index";
import { Link, useNavigate, useParams } from "react-router-dom";
import Threelinks from "../../components/Threelinks";
import {
	useProductContentQuery,
	useProductDetailQuery,
} from "../../store/services/ProductService";
import moment from "moment/moment";
import PostReview from "./PostReview";
import FeatureProduct from "../../components/FeatureProduct";
import currency from "currency-formatter";
import { useDispatch, useSelector } from "react-redux";
import { addCart, addQuantity } from "../../store/reducers/CartrReducer";
import { toast } from "react-toastify";
import Spinner from "../../utils/Loader";

function Shopdetail() {
	let Data = {
		featured: 1,
	};
	const { data: feturedProduct, isLoading: feturedLoading } =
		useProductContentQuery(Data);
	const feturedProductData = feturedProduct?.response?.data?.products;
	const [variation, setVariation] = useState();

	const { slug } = useParams();
	const { data, isLoading } = useProductDetailQuery(slug);
	let detailData = data?.response?.data;
	const { userToken } = useSelector((item) => item.AuthReducer);
	const navigate = useNavigate();
	const [count, setCount] = useState(detailData?.current_stock > 0 ? 1 : 0);
	const [activeClass, setActiveClass] = useState(false);
	const dispatch = useDispatch();

	const [counter, setCounter] = useState(1);
	const handleClick1 = () => {
		// Counter state is incremented
		if (counter >= variation?.qty) {
			toast.error(`Product quantity is increasing from available stock.`);
			setActiveClass(true);
			return;
		} else if (detailData?.current_stock === 0) {
			toast.error(`Out Of Stock`);
			setActiveClass(true);
			return;
		}
		setCounter(counter + 1);
	};
	const handleClick2 = () => {
		// Counter state is decremented
		if (counter < 1) {
			return setCount(1);
		}
		setCounter(counter - 1);
		setActiveClass(false);
		return;
	};

	const HandleCart = (item) => {
		// if (!userToken) {
		// 	return toast.error("Please Login");
		// }

		if (variation?.qty === 0) {
			toast.error(`${item?.name} Out Of Stock`);
			return;
		}

		if (variation === undefined) {
			toast.error(`Please Select Gram Variation`);
			return;
		}

		if (counter === 0) {
			toast.error(`Please Select Quantity`);
			return;
		}
		const cart = localStorage.getItem("cart");
		const cartItems = cart ? JSON.parse(cart) : [];
		const checkItem = cartItems.find(
			(items) =>
				items.variation_name === variation?.type && items?.id === item?.id,
		);
		if (!checkItem) {
			let MyItem2 = Object.assign({}, item, {
				quantity: counter,
				variation_price: variation?.price,
				variation_name: variation?.type,
				variation_qty: variation?.qty,
			});
			dispatch(addCart(MyItem2));
			cartItems.push(MyItem2);
			toast.success(`${item.name} is add in cart`);
			localStorage.setItem("cart", JSON.stringify(cartItems));
			navigate("/cart");
		} else {
			const cart = localStorage.getItem("cart");
			const cartItems = cart ? JSON.parse(cart) : [];
			const checkItem = cartItems.find(
				(items) =>
					items.variation_name === variation?.type && items?.id === item?.id,
			);
			if (checkItem?.quantity + counter > variation?.qty) {
				toast.error(`Product quantity is increasing from available stock.`);
				setActiveClass(true);
			} else {
				let obj = {
					quantity: counter,
					id: item?.id,
				};
				dispatch(addQuantity(obj));
				setActiveClass(false);
				toast.success(`${item.name} is add in cart`);
				navigate("/cart");
			}
		}
	};

	let proReviews = detailData?.reviews;

	return (
		<>
			{!isLoading ? (
				<>
					<Header />
					{/* Shop detail section     */}
					<section className="shop-detail-sec">
						<div className="container">
							<div className="row">
								<div className="col-md-6">
									<Slide proImage={detailData} />
								</div>

								<div className="col-md-6">
									<div className="all-shop-cart">
										<div className="shop-head">
											<h5>
												{detailData?.current_stock > 0
													? "IN STOCK"
													: "OUT OF STOCK"}
											</h5>
											<h2>
												{detailData?.name}
												<span>.</span>
											</h2>
										</div>

										<div className="shop-reviews-all">
											<div className="sop-cutomer-reivies">
												<div className="stars">
													{detailData?.rating[0]?.average > 1
														? [
																...Array(
																	parseInt(detailData?.rating[0]?.average),
																),
														  ].map((star, index) => {
																return (
																	<i
																		key={index}
																		className="fa fa-star"
																		aria-hidden="true"
																	></i>
																);
														  })
														: "No Rating"}
												</div>

												<div className="customer">
													<p>
														{detailData?.rating < 1
															? "(0 customer reviews)"
															: `(${detailData?.reviews?.length} customer reviews)`}
													</p>
												</div>
											</div>

											<div className="quick-reiview">
												<h3>Quick Overview</h3>

												<div className="sevev-dis">
													<div className="variation-input">
														{detailData?.variant_array?.map((item, index) => {
															return (
																<>
																	<label
																		className="variation-container"
																		onClick={() => setVariation(item)}
																	>
																		<input
																			className="form-check-input mt-0"
																			type="radio"
																			hidden
																			name="variaInput"
																			aria-label="Radio button for following text input"
																		/>
																		<span className="checkmark">
																			<h4>{`${item?.type}`}</h4>
																		</span>
																	</label>
																</>
															);
														})}
													</div>
												</div>
											</div>

											<div className="para-customer-review">
												<p
													dangerouslySetInnerHTML={{
														__html: detailData?.details,
													}}
												></p>
											</div>

											<div className="para-us">
												<h5>
													{currency.format(
														variation != undefined
															? variation?.price
															: detailData?.unit_price,
														{
															code: "USD",
														},
													)}{" "}
													<span>
														THC : ({detailData?.thc ? detailData?.thc : "0"}%){" "}
														{variation?.qty ? `QTY:${variation?.qty}` : ""}
													</span>
												</h5>
											</div>
											<div className="quainty-and-button">
												<div className="quantity">
													<button onClick={handleClick2}>
														<i className="fa fa-minus" aria-hidden="true"></i>
													</button>
													<input value={counter} />
													<button
														onClick={() => handleClick1(detailData?.id)}
														disabled={activeClass ? "true" : ""}
													>
														{/* activeClass */}
														<i
															className={
																activeClass
																	? `fa fa-plus cancelbtns`
																	: `fa fa-plus`
															}
															aria-hidden="true"
														></i>
													</button>
												</div>

												<div className="banner-button Add-to-cart-on-detail-page login-btn">
													<button
														onClick={() => HandleCart(detailData)}
														className="all-button submitButton"
													>
														ADD TO CART{" "}
														<img className="img-fluid" src={arrow} />
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<Threelinks />
					</section>
					{/* ================================================ */}

					{/* Tabs section */}
					<section className="reviews-tab-sec">
						<div className="container">
							<div className="row">
								<div className="col-md-12">
									<div className="tabs-alls">
										<ul
											className="nav nav-pills mb-3"
											id="pills-tab"
											role="tablist"
										>
											<li className="nav-item" role="presentation">
												<button
													className="nav-link active"
													id="pills-home-tab"
													data-bs-toggle="pill"
													data-bs-target="#pills-home"
													type="button"
													role="tab"
													aria-controls="pills-home"
													aria-selected="true"
												>
													DESCRIPTION
												</button>
											</li>
											<li className="nav-item" role="presentation">
												<button
													className="nav-link"
													id="pills-profile-tab"
													data-bs-toggle="pill"
													data-bs-target="#pills-profile"
													type="button"
													role="tab"
													aria-controls="pills-profile"
													aria-selected="false"
												>
													REVIEWS{" "}
													<span className="for-number">
														{detailData?.reviews?.length}
													</span>
												</button>
											</li>
										</ul>
										<div className="tab-content" id="pills-tabContent">
											<div
												className="tab-pane fade show active"
												id="pills-home"
												role="tabpanel"
												aria-labelledby="pills-home-tab"
											>
												<div className="description-para">
													<p
														dangerouslySetInnerHTML={{
															__html: detailData?.details,
														}}
													></p>
												</div>
											</div>
											<div
												className="tab-pane fade"
												id="pills-profile"
												role="tabpanel"
												aria-labelledby="pills-profile-tab"
											>
												<div className="reviews-all">
													<div className="review-para">
														<p>{`${detailData?.reviews?.length} reviews for Our Products`}</p>
													</div>
													{/* Reviews-Row   */}
													{!isLoading ? (
														<>
															{proReviews?.map((data, index) => {
																return (
																	<div className="row for-margin" key={index}>
																		<div className="col-md-1">
																			<div className="review-img">
																				{!data?.customer?.profile_pic ? (
																					<img
																						className="img-fluid"
																						src={dummyImage}
																					/>
																				) : (
																					<img
																						src={
																							data?.customer?.profile_pic_url
																						}
																						style={{
																							width: "100%",
																							height: "50px",
																							objectFit: "cover",
																						}}
																					/>
																				)}
																			</div>
																		</div>
																		<div className="col-md-11">
																			<div className="review-tab-all">
																				<div className="tab-text">
																					<h5>
																						{data?.name == "undefined"
																							? "No name "
																							: data?.name}
																						<span>
																							-{" "}
																							{moment(data?.created_at).format(
																								"MMM Do YY",
																							)}
																						</span>
																					</h5>
																					<p>
																						{data?.comment == "undefined"
																							? "No comment"
																							: data?.comment}
																					</p>
																				</div>
																				<div className="stars reviews-stars">
																					{data?.rating > 1
																						? [
																								...Array(
																									parseInt(data?.rating),
																								),
																						  ].map((star, index) => {
																								return (
																									<i
																										key={index}
																										className="fa fa-star"
																										aria-hidden="true"
																									></i>
																								);
																						  })
																						: "No Rating"}
																				</div>
																			</div>
																		</div>
																	</div>
																);
															})}
														</>
													) : (
														<h1>Loading...</h1>
													)}
													{/* Reviews-Row   */}
													<PostReview id={detailData?.id} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* Tabs section */}

					{/* Feature Product Section */}
					<FeatureProduct />
					{/* Feature Product Section */}

					<Footer />
				</>
			) : (
				<>
					<Spinner />
				</>
			)}
		</>
	);
}

export default Shopdetail;
