import logo from "./logo.svg";
import "./App.css";
import style from "../src/assets/css/style.css";
import Publicroutes from "./routes/Publicroutes";
import { Provider } from "react-redux";
import Index from "./store/Index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
	return (
		<>
			<Provider store={Index}>
				<ToastContainer />
				<Publicroutes />
				<ToastContainer />
			</Provider>
		</>
	);
}

export default App;
