import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import checkout from "../../assets/css/checkout.css";
import "../../assets/css/profile.css";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../components/Pageheader";
import { arrow, checkoutimg } from "../../constant/Index";
import Threelinks from "../../components/Threelinks";
import PaymentMethod from "./PaymentMethod";
import { useCouponCodeApplyMutation } from "../../store/services/SiteSettingService";
import { useDispatch, useSelector } from "react-redux";
import { useUsermeInfoQuery } from "../../store/services/AuthServices";
import currency from "currency-formatter";
import {
	useDefaultAddressMutation,
	useGetAddresslistQuery,
} from "../../store/services/AddAddressServices";
import { useEffect } from "react";
import {
	clearCouponDiscount,
	couponDiscount,
} from "../../store/reducers/MembershipReducer";
import { usePlaceOrderApisMutation } from "../../store/services/PlaceOrderService";
import { toast } from "react-toastify";

function Checkout() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [state, setStates] = useState({
		f_Name: "",
		l_Name: "",
		address: "",
		email: "",
		suite: "",
		city: "",
		zip: "",
		phone: "",
		states: "",
		country: "",
	});
	const { cart, total } = useSelector((state) => state.CartrReducer);
	const { cardNonce, coupondiscount } = useSelector(
		(state) => state.MembershipReducer,
	);
	const [condition, setCondition] = useState(false);
	const [placeOrderData, placeOderresponse] = usePlaceOrderApisMutation();
	const [applyCoupon, response] = useCouponCodeApplyMutation();
	const [couponCode, setCouponCode] = useState();
	const { data, isLoading } = useUsermeInfoQuery();
	const ProfileData = data?.response?.data;
	const CouponDiscount = response?.data?.response?.data?.discount_price;

	const { data: userAddress, isLoading: load } = useGetAddresslistQuery();
	const [checkDefault, setDefault] = useState();
	const [defaultAddress, responsedata] = useDefaultAddressMutation();
	const myAddressData = userAddress?.response?.data;

	const HandleApplyCode = (e) => {
		e.preventDefault();
		if (!couponCode) {
			return toast.error("Please Enter Coupon Code");
		} else {
			const formData = new FormData();
			formData.append("code", couponCode);
			formData.append("total_amount", total);
			applyCoupon(formData);
		}
	};
	let CatDatas = cart?.map((item) => {
		return {
			id: item?.id,
			qty: item?.quantity,
			variant: item?.variation_name,
		};
	});

	const PlaceOrderData = (e) => {
		e.preventDefault();
		const formData = new FormData();
		if (checkDefault !== undefined) {
			if (!cardNonce) {
				return toast.error("Please Enter Your Card Number");
			} else if (condition === false) {
				return toast.error("Please Check Condition");
			} else {
				formData.append("coupon_code", couponCode ? couponCode : null);
				formData.append(
					"coupon_discount",
					coupondiscount ? coupondiscount : null,
				);
				formData.append("address_id", checkDefault);
				formData.append("cart", JSON.stringify(CatDatas));
				formData.append("payment_method", "square");
				formData.append("card_nonce", cardNonce);
				formData.append("shipping_id", null);
				placeOrderData(formData);
			}
		} else {
			if (
				!state?.f_Name ||
				!state?.l_Name ||
				!state?.address ||
				!state?.suite ||
				!state?.zip ||
				!state?.phone ||
				!state?.states ||
				!state?.country
			) {
				return toast.error("Please Enter All fileds");
			} else {
				if (!cardNonce) {
					return toast.error("Please Enter Your Card Number");
				} else if (condition === false) {
					return toast.error("Please Check Condition");
				} else {
					formData.append("first_name", state?.f_Name);
					formData.append("last_name", state?.l_Name);
					formData.append("address", state?.address);
					formData.append("suite", state?.suite);
					formData.append("city", state?.city);
					formData.append("zip", state?.zip);
					formData.append("phone", state?.phone);
					formData.append("state", state?.states);
					formData.append("country", state?.country);
					formData.append("coupon_code", couponCode ? couponCode : null);
					formData.append("shipping_id", null);
					formData.append(
						"coupon_discount",
						coupondiscount ? coupondiscount : null,
					);
					formData.append("payment_method", "square");
					formData.append("card_nonce", cardNonce);
					formData.append("address_id", "");
					formData.append("cart", JSON.stringify(CatDatas));
					placeOrderData(formData);
				}
			}
		}
	};

	const HandleChanger = (e) => {
		setStates({ ...state, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		if (placeOderresponse?.isError) {
			toast.error(placeOderresponse?.error?.data?.message);
		}
	}, [placeOderresponse?.error?.data]);

	let obj = {
		addressId: checkDefault ? checkDefault : null,
		couponCode: coupondiscount ? coupondiscount : null,
		totalAmount: total,
		address: state?.address ? state?.address : "",
		country: state?.country ? state?.country : "",
		phone: state?.phone ? state?.phone : "",
		state: state?.states ? state?.states : "",
		location: "/checkout",
	};
	useEffect(() => {
		if (placeOderresponse?.isSuccess) {
			toast.success(placeOderresponse?.data?.message);
			navigate("/thank-you", { state: obj });
		}
	}, [placeOderresponse?.data?.message]);

	useEffect(() => {
		if (response?.isSuccess) {
			if (response?.data?.message == "No Coupon found") {
				toast.error(response?.data?.message);
			} else {
				dispatch(
					couponDiscount(response?.data?.response?.data?.discount_price),
				);
				toast.success(response?.data?.message);
			}
		}
	}, [response?.data?.message]);

	useEffect(() => {
		if (response?.isError) {
			if (response?.isError && response?.error?.data?.errors) {
				for (let key in response?.error?.data?.errors) {
					if (response?.error?.data?.errors.hasOwnProperty(key)) {
						toast.error(response?.error?.data?.errors[0]?.message);
					}
				}
			}
		}
	}, [response?.error?.data]);

	if (cart.length === 0) {
		return navigate("/shop");
	}

	return (
		<>
			<Header />
			<PageHeader pagename="Checkout" />
			{/* <!-- Checkout Start Here --> */}
			<section className="check-out-sec checkout">
				<div className="container">
					<div className="row">
						<div className="col-md-7 ">
							<div className="all-address-box">
								{myAddressData?.map((item, index) => {
									return (
										<div className="my-all-address" key={index}>
											<div className="saved-address">
												{/* <!-- Saved Address --> */}
												<label className="address-container">
													<input
														type="radio"
														name="addressRadio"
														className="form-check-input"
														value={checkDefault}
														onChange={() => setDefault(item.id)}
													/>
													<span className="checkmark">
														<div className="select-address">
															<div className="selection">
																<i
																	className="fa fa-check"
																	aria-hidden="true"
																></i>
															</div>
															<div className="radio-btn">
																<span></span>
															</div>
															<div className="address-detail">
																<div className="top">
																	<h5>
																		<span className="property">Country:</span>
																		<span className="value">
																			{item?.country}
																		</span>
																	</h5>
																	<h5>
																		<span className="property pl-4">
																			State:
																		</span>
																		<span className="value">{item?.state}</span>
																	</h5>
																</div>
																<h5>
																	<span className="property">Address:</span>
																	<span className="value">{item?.address}</span>
																</h5>
																<h5 className="last">
																	<span className="property">Zip Code:</span>
																	<span className="value">{item?.zip}</span>
																</h5>
																<div className="button-group address-edit-btn">
																	<Link to={`/edit-address/${item?.id}`}>
																		Edit Details
																	</Link>
																</div>
															</div>
														</div>
													</span>
												</label>
												{/* <!-- Saved Address --> */}
											</div>
										</div>
									);
								})}
								<label className="address-container">
									<input
										type="radio"
										name="addressRadio"
										className="form-check-input"
										value={checkDefault}
										onChange={() => setDefault(undefined)}
										defaultChecked
										hidden
									/>
									<div className="billin-dv">
										<h4>BILLING DETAILS</h4>
									</div>
									<span className="checkmark">
										<div className="check-dot"></div>
										<div className="add-new-address">
											<div className="row">
												<div className="col-md-6">
													<div className="form-dv-input check-out-input">
														<label>First Name</label>
														<input
															type="text"
															name="f_Name"
															value={state?.f_Name}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-dv-input check-out-input">
														<label>Last Name</label>
														<input
															type="text"
															name="l_Name"
															value={state?.l_Name}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-dv-input check-out-input">
														<label>Phone</label>
														<input
															type="number"
															name="phone"
															value={state?.phone}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-dv-input check-out-input">
														<label>Email</label>
														<input
															type="email"
															name="email"
															value={state?.email}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-dv-input check-out-input">
														<label>Address</label>
														<input
															type="text"
															name="address"
															value={state?.address}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-dv-input check-out-input">
														<label>Suite, Apartment, etc </label>
														<input
															type="text"
															name="suite"
															value={state?.suite}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-dv-input check-out-input">
														<label>City</label>
														<input
															type="text"
															name="city"
															value={state?.city}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-dv-input check-out-input">
														<label>Country/Region</label>
														<input
															type="text"
															name="country"
															value={state?.country}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-dv-input check-out-input">
														<label>State</label>
														<input
															type="text"
															name="states"
															value={state?.states}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-dv-input check-out-input">
														<label>Zip Code</label>
														<input
															type="text"
															name="zip"
															value={state?.zip}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												{/* <div className="col-md-12">
													<div className="check-and-text">
														<div className="form-group">
															<input type="checkbox" id="check" />
															<label for="check">
																Save Shipping Details
															</label>
														</div>
													</div>
												</div> */}
											</div>
										</div>
									</span>
								</label>
								<div className="all-checkout">
									<form>
										<div className="row">
											<div className="col-md-12">
												<div className="form-dv-input check-out-input">
													<label>Payment Details</label>
													{/* <input type="text" placeholder="mm/yy       cvc" /> */}
													<PaymentMethod
														cartData={cart}
														totalAmount={total}
														discountCode={coupondiscount ? coupondiscount : 0}
													/>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>

						<div className="col-md-5">
							<div className="summary-dv">
								<h4>ORDER SUMMARY</h4>
							</div>

							<div className="product-detail-all product-copun">
								<div className="product-head coupon-head">
									<h5>APPLY COUPON</h5>
									<p>If you have a coupon code, please apply it below.</p>
								</div>
								<div className="coupon-input">
									<input
										type="text"
										value={couponCode}
										onChange={(e) => setCouponCode(e.target.value)}
									/>
									<div className="coupon-dv">
										<div className="banner-button  cart-coupon-btn">
											<button className="all-button" onClick={HandleApplyCode}>
												{response?.isLoading ? "Loading..." : "APPLY COUPON"}{" "}
												<img className="img-fluid" src={arrow} />{" "}
											</button>
										</div>
									</div>
								</div>
							</div>

							<div className="product-detail-all check-out-detail">
								<div className="product-head">
									<h5>PRODUCT DETAILS</h5>
								</div>
								<div className="for-over-flow">
									{cart?.length > 0 &&
										cart?.map((item, index) => {
											return (
												<div className="pro-first-dv">
													<div className="pro-img">
														<img className="img-fluid" src={item?.thumbnail} />
														<div className="pro-text">
															<p>{item?.name}</p>
															<p className="for-font-change">
																({item?.quantity}x) ({item?.variation_name})
															</p>
														</div>
													</div>

													<div className="pro-price">
														<p>
															{currency.format(item?.variation_price, {
																code: "USD",
															})}
														</p>
													</div>
												</div>
											);
										})}
								</div>

								<div className="pament-details">
									<h5>PAYMENT DETAILS</h5>
								</div>

								<div className="all-total">
									<div className="total">
										<p className="for-total-css">Total :</p>
										<p className="for-total-green">
											{currency.format(total, {
												code: "USD",
											})}
										</p>
									</div>
									{coupondiscount && (
										<div className="total">
											<p className="for-total-css">Coupon Discount :</p>
											<p className="for-total-green">
												{currency.format(coupondiscount, {
													code: "USD",
												})}
											</p>
										</div>
									)}
								</div>
							</div>
							<div className="ordr-total">
								<p className="for-order-total-css">Order Total : </p>
								<p className="for-total-white">
									{currency.format(
										coupondiscount ? total - coupondiscount : total,
										{
											code: "USD",
										},
									)}
								</p>
							</div>
						</div>
					</div>
					{/* Second Row */}
					<div className="row check-box-row">
						<div className="col-md-6">
							<div className="check-and">
								<div className="form-group">
									<input
										type="checkbox"
										id="bottom-check"
										name="condition"
										value={condition}
										onChange={() => setCondition(!condition)}
									/>
									<label for="bottom-check">
										<span>
											Proident, sunt in culpa qui officia deserunt mollit anim
											id est laborum.
										</span>
									</label>
								</div>
							</div>
						</div>

						<div className="col-md-6">
							<div className="banner-button checkout-btns login-btn">
								<button
									className="all-button submitButton"
									onClick={PlaceOrderData}
								>
									{placeOderresponse?.isLoading
										? "Loading...."
										: "CONFIRM PAYMENT"}{" "}
									<img className="img-fluid" src={arrow} />{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* =============================================== */}
			<Threelinks />
			<Footer />
		</>
	);
}

export default Checkout;
