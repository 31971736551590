import React from "react";
import Header from "../../components/Header";
import { arrow, contact } from "../../constant/Index";
import cont from "../../assets/css/contact.css";
import { Link } from "react-router-dom";
import Threelinks from "../../components/Threelinks";
import Footer from "../../components/Footer";
import { useContactContentMutation } from "../../store/services/ContactService";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useCoreimageContentQuery } from "../../store/services/ImagesUrlService";

function Contact() {
	// Contact Info Api Call
	const { data, isLoading } = useCoreimageContentQuery();
	let Contactinfo = data?.response?.data;
	// Contact Info Api Call

	const [fname, setFname] = useState("");
	const [lname, setLname] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");
	const [ContactData, response] = useContactContentMutation();

	const handleSubmit = (e) => {
		e.preventDefault();
		if (fname === "") {
			toast.error("First Name field is empty");
		} else if (lname === "") {
			toast.error("Last Name field is empty");
		} else if (email === "") {
			toast.error("Email field is empty");
		} else if (phone === "") {
			toast.error("Phone number field is empty");
		} else if (message === "") {
			toast.error("Message field is empty");
		} else {
			const formData = new FormData();
			formData.append("first_name", fname);
			formData.append("last_name", lname);
			formData.append("email", email);
			formData.append("phone", phone);
			formData.append("message", message);
			ContactData(formData);
		}
	};

	useEffect(() => {
		if (response?.isError && response?.error?.data?.errors) {
			for (let key in response?.error?.data?.errors) {
				if (response?.error?.data?.errors.hasOwnProperty(key)) {
					toast.error(response?.error?.data?.errors[key][0]);
				}
			}
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response?.isSuccess) {
			toast.success(response?.data?.response?.data);
			setFname("");
			setLname("");
			setEmail("");
			setPhone("");
			setMessage("");
		}
	}, [response?.data?.response?.data]);
	return (
		<>
			<Header />

			{/* Contact Section */}
			<section className="contact-sec">
				<div className="container">
					<div className="row for-border-and-color">
						<div className="col-md-4">
							<div className="contact-img">
								<img className="img-fluid" src={contact} />
								<div className="call-now-green-box">
									<div className="call call-now-anchor">
										<h3>Call Now</h3>
										<a href="tel:+84 (400) 333 5526">
											{Contactinfo?.[0]?.value}
										</a>
										<a
											className="anchor-edit"
											href="mailto:pipeparadise@example.com"
										>
											{Contactinfo?.[4]?.value}
										</a>
									</div>

									<div className="call opening-hour for-border">
										<h3> OPENING HOURS</h3>
										<p>Mon - Fri: 5:00 pm – 10:00 pm</p>
										<p>Mon - Fri: 5:00 pm – 10:00 pm</p>
									</div>

									<div className="call opening-hour locat">
										<h3>LOCATION</h3>
										<p>{Contactinfo?.[8]?.value}</p>
									</div>
								</div>
							</div>
						</div>

						<div className="col-md-8">
							<div className="contact-form-all">
								<div className="about-banner-text contact-upper-head">
									{/* <h5>S M O K E <span className="for-gap-in-words">P I P E</span> E V E R Y D A Y</h5> */}
									<h2>
										CONTACT US FORM<span>.</span>
									</h2>
									<p>
										Your email address will not be published.required fields are
										marked <span>*</span>
									</p>
								</div>
								<div className="form-for-contact">
									<form>
										<div className="row">
											<div className="col-md-6">
												<div className="contact-form-input">
													<input
														type="text"
														name="fname"
														value={fname}
														onChange={(e) => setFname(e.target.value)}
														placeholder="First Name"
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="contact-form-input">
													<input
														type="text"
														name="lname"
														value={lname}
														onChange={(e) => setLname(e.target.value)}
														placeholder="Last Name"
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="contact-form-input">
													<input
														type="text"
														name="email"
														value={email}
														onChange={(e) => setEmail(e.target.value)}
														placeholder="Email Address*"
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="contact-form-input">
													<input
														type="number"
														name="phone"
														value={phone}
														onChange={(e) => setPhone(e.target.value)}
														placeholder="Phone Number*"
													/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="contact-form-textarea">
													<textarea
														name="phone"
														value={message}
														onChange={(e) => setMessage(e.target.value)}
														className="form-control"
														id="exampleFormControlTextarea1"
														rows="3"
														placeholder="Type your message here.."
													></textarea>
												</div>
											</div>
											<div className="col-md-6"></div>
											<div className="col-md-6">
												<div className="banner-button submit-banner-btn login-btn">
													<button
														onClick={handleSubmit}
														className="all-button submitButton"
													>
														{response?.isLoading ? "Loading..." : "SUBMIT"}
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Threelinks />
			</section>
			{/* ========================================= */}
			<Footer />
		</>
	);
}

export default Contact;
