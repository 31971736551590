import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const AddressServices = createApi({
	reducerPath: "address",
	tagTypes: "address",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://pipeparadise.developer-ourbase-camp.com/api/v1",
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			headers.set("Access-Control-Allow-Origin", "*");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			addAddress: builder.mutation({
				query: (addressData) => {
					return {
						url: "/customer/address/add",
						method: "POST",
						body: addressData,
					};
				},
				invalidatesTags: ["editInfo"],
			}),
			getAddresslist: builder.query({
				query: () => {
					return {
						url: "/customer/address/list",
						method: "GET",
					};
				},
				providesTags: ["editInfo"],
			}),
			updateAddresslist: builder.mutation({
				query: (datas) => {
					return {
						url: `customer/address/update/${datas?.id}`,
						method: "POST",
						body: datas?.data,
					};
				},
				invalidatesTags: ["editInfo"],
			}),
			getSingleAddress: builder.query({
				query: (id) => {
					return {
						url: `/customer/address/list?id=${id}`,
						method: "GET",
					};
				},
			}),
			defaultAddress: builder.mutation({
				query: (id) => {
					return {
						url: `/customer/address/make-default`,
						method: "POST",
						body: id,
					};
				},
			}),
		};
	},
});

export const {
	useAddAddressMutation,
	useGetAddresslistQuery,
	useUpdateAddresslistMutation,
	useGetSingleAddressQuery,
	useDefaultAddressMutation,
} = AddressServices;
export default AddressServices;
