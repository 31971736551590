import { useDispatch } from "react-redux";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { cardNoneData } from "../../store/reducers/MembershipReducer";
import { toast } from "react-toastify";

export default function PaymentMethod({ cartData, totalAmount, discountCode }) {
	const dispatch = useDispatch();
	return (
		<PaymentForm
			applicationId="sandbox-sq0idb-e1ww-hHoy1u3sGz_3AP53g"
			locationId="LQ19BFVB5Z6A5"
			createPaymentRequest={() => ({
				countryCode: "US",
				currencyCode: "USD",
				lineItems: [
					cartData?.map((item, index) => {
						return {
							amount: item?.variation_price,
							label: item?.name,
							id: "SKU-12345",
							pending: true,
							productUrl: item?.thumbnail,
						};
					}),
				],
				requestBillingContact: false,
				requestShippingContact: false,
				total: {
					amount: discountCode ? totalAmount - discountCode : totalAmount,
					label: "Total",
				},
			})}
			cardTokenizeResponseReceived={async (token) => {
				dispatch(cardNoneData(token?.token));
				toast.success(
					"Your card details has been saved, please confirm to place order.",
				);
			}}
		>
			<CreditCard render={(Button) => <Button>Save</Button>} />
		</PaymentForm>
	);
}
