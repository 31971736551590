import React from "react";
import { useMembershipContentQuery } from "../store/services/Membership";
import { arrow, blackflower, dollar } from "../constant/Index";
import { Link, navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setmemberData } from "../store/reducers/MembershipReducer";

const Membership = () => {
	const { data, isLoading } = useMembershipContentQuery();
	let membershipData = data?.response?.data;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const HandleClick = (e, data) => {
		e.preventDefault();
		dispatch(setmemberData(data));
		navigate("/member-checkout");
	};
	return (
		<>
			{membershipData?.map((data, index) => {
				return (
					<div className="col-md-4 set_membership_details" key={data?.id}>
						<div className="all-become-member-div">
							<div className="first-dv">
								<div className="member-text">
									<h5>{data?.title}</h5>
									<h2>
										<span className="dollar">
											<img className="img-fluid" src={dollar} />
										</span>
										{data?.price}
										<span> /{data?.type}</span>
									</h2>
								</div>
							</div>
							<div className="become-member-ul">
								<p className="paragraf_member" dangerouslySetInnerHTML={{ __html: data?.description }} />

								{/* <ul className="member-ul">
							<li>
								{" "}
								<span>
									<img className="img-fluid" src={blackflower} />
								</span>{" "}
								Member Card
							</li>
							<li>
								{" "}
								<span>
									<img className="img-fluid" src={blackflower} />
								</span>{" "}
								Earn Bonus
							</li>
							<li>
								{" "}
								<span>
									<img className="img-fluid" src={blackflower} />
								</span>{" "}
								Lounge Access
							</li>
							<li>
								{" "}
								<span>
									<img className="img-fluid" src={blackflower} />
								</span>{" "}
								Free Sample
							</li>
							<li>
								{" "}
								<span>
									<img className="img-fluid" src={blackflower} />
								</span>{" "}
								Special Offers
							</li>
						</ul> */}

								<div className="banner-button submit-banner-btn login-btn member_btns">
									<button
										onClick={(e) => HandleClick(e, data)}
										className="all-button submitButton"
									>
										BUY NOW <img className="img-fluid" src={arrow} />{" "}
									</button>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default Membership;
