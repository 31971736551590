import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import thankyou from "../../assets/css/thankyou.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { useGetSingleAddressQuery } from "../../store/services/AddAddressServices";
import currency from "currency-formatter";
import {
	clearCardNonce,
	clearCouponDiscount,
	memberclearCardNonce,
} from "../../store/reducers/MembershipReducer";
import { useDispatch } from "react-redux";
import { emptyCart } from "../../store/reducers/CartrReducer";
import Spinner from "../../utils/Loader";

function Thankyou() {
	const { state } = useLocation();
	const dispatch = useDispatch();
	const { data, isLoading } = useGetSingleAddressQuery(state?.addressId);
	const myAddressData = data?.response?.data?.address?.[0];

	useEffect(() => {
		return () => {
			if (state?.location === "/checkout") {
				dispatch(clearCardNonce());
				dispatch(emptyCart());
				dispatch(clearCouponDiscount());
			} else {
				dispatch(memberclearCardNonce());
				dispatch(clearCouponDiscount());
			}
		};
	}, [state?.location]);

	return !isLoading ? (
		<>
			<Header />

			{/* Thank You Section */}

			<section className="thank-you-sec">
				<div className="container">
					<div className="thank-you-head">
						<h3>Thank You for Shopping!!</h3>
						<h4>Your Order is confirmed</h4>
						{/* <h5>OrderNo # 1084598423154</h5> */}
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="thank-you-all">
								<div className="inner-all">
									<div className="thank-for-shopping-dv first-div">
										<div className="shippment-heading">
											<h3>
												{state?.location === "/checkout"
													? "SHIPMENT ADDRESS"
													: "BILLING ADDRESS"}
											</h3>
										</div>
										<div className="inner-stuff">
											<p className="for-light">Country:</p>
											<p className="for-dark">
												{state?.country
													? state?.country
													: myAddressData?.country}
											</p>
										</div>
										<div className="inner-stuff">
											<p className="for-light">Address:</p>
											<p className="for-dark">
												{state?.address
													? state?.address
													: myAddressData?.address}
											</p>
										</div>

										{state?.phone && (
											<div className="inner-stuff">
												<p className="for-light">Phone:</p>
												<p className="for-dark">
													<a href="tel+44-123-456-789">{state?.phone}</a>
												</p>
											</div>
										)}

										<div className="inner-stuff">
											<p className="for-light">State:</p>
											<p className="for-dark">
												{state?.state ? state?.state : myAddressData?.state}
											</p>
										</div>
									</div>

									<div className="thank-for-shopping-dv second-div">
										<div className="shippment-heading">
											<h3>PAYMENT DETAILS</h3>
										</div>
										<div className="inner-stuff-price">
											<p className="for-light">Total :</p>
											<p className="for-dark">
												{" "}
												{currency.format(state?.totalAmount, {
													code: "USD",
												})}
											</p>
										</div>
										{state?.couponCode && (
											<div className="inner-stuff-price">
												<p className="for-light">Coupon Code Discount : </p>
												<p className="for-dark">
													{currency.format(state?.couponCode, {
														code: "USD",
													})}
												</p>
											</div>
										)}

										<div className="inner-stuff-price for-extra-padding">
											<p className="for-light">Order Total :</p>
											<p className="for-dark">
												{currency.format(
													state?.couponCode
														? state?.totalAmount - state?.couponCode
														: state?.totalAmount,
													{
														code: "USD",
													},
												)}
											</p>
										</div>
									</div>
								</div>
							</div>
							{state?.location === "/checkout" ? (
								<div className="banner-button submit-banner-btn login-btn thanku-btn">
									<Link to="/shop" className="all-button">
										CONTINUE SHOPPING
									</Link>
								</div>
							) : (
								<div className="banner-button submit-banner-btn login-btn thanku-btn">
									<Link to="/member-ship" className="all-button">
										Check Membership
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
			{/* ========================================= */}
			<Threelinks />
			<Footer />
		</>
	) : (
		<Spinner />
	);
}

export default Thankyou;
