import React from "react";
import { Link } from "react-router-dom";
import {
	about1,
	about2,
	about3,
	abouticon,
	arrow,
	hand,
	homeabout,
} from "../../constant/Index";
import about from "../../assets/css/about.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Threelinks from "../../components/Threelinks";
import { useAboutContentQuery } from "../../store/services/HomeAboutService";
import { useImageContentQuery } from "../../store/services/ImagesUrlService";
import { useSettingContentQuery } from "../../store/services/SiteSettingService";
import Spinner from "../../utils/Loader";

function About() {
	// About Us Api Call
	const { data, isLoading } = useAboutContentQuery();
	let aboutContent = data?.response?.data;
	// About Us Api Call

	// ImageUrl Api Call
	const { data: ImgData, isLoading: ImgLoading } = useImageContentQuery();
	let about_img = ImgData?.response?.data?.home_about_us_image_url;
	// ImageUrl Api Call

	// Sitesetting Api Call
	const { data: siteData, isLoading: siteLoading } = useSettingContentQuery();
	let contactBannerData = siteData?.response?.data[0]?.contact_us;
	// Sitesetting Api Call
	return (
		<>
			{!isLoading ? (
				<>
					<Header />
					{/* About section */}
					{aboutContent?.map((data, index) => {
						return (
							<section
								className={
									index % 2 === 0
										? "banner-about-sec about-ban-sec about-sec-first-ban inner-about-para-color "
										: "banner-about-sec about-ban-sec about-sec-first-ban inner-about-para-color reverse_row"
								}
								key={index}
							>
								<div className="container">
									<div className="row">
										<div className="col-md-6">
											<div
												className={
													index % 2 === 0
														? "about-img"
														: "about-img reverse-img"
												}
											>
												{/* <div className="about-icon-img">
											<div className="img-1">
												<img className="img-fluid" src={hand} />
												<h5>SMOKE LOUNGE</h5>
											</div>
										</div> */}
												<img
													className="img-fluid"
													src={about_img + "/" + data?.image}
												/>
												{/* <div className="about-icon-img2">
											<div className="img-2">
												<img className="img-fluid" src={abouticon} />
												<h5>CANABIS SMOKING</h5>
											</div>
										</div> */}
											</div>
										</div>
										<div className="col-md-6">
											<div className="about-banner-text">
												<h5>{data?.sub_title}</h5>
												<h2>
													{data?.title}
													<span>.</span>
												</h2>
												<p>{data?.description}</p>

												<div className="banner-button btn-for-about-ban">
													<Link to="/shop" className="all-button">
														SHOP NOW
														<img className="img-fluid" src={arrow} />{" "}
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
								<Threelinks />
							</section>
						);
					})}
					{/* ================================================================= */}

					{/* About-section-second  */}
					{/* <section className="banner-about-sec about-second-sec inner-about-para-color">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="about-banner-text">
								<h5>
									PRIVATE <span className="for-gap-in-words">MEMBERS</span>{" "}
									LOUNGE
								</h5>
								<h2>
									ABOUT orderzaza<span>.</span>
								</h2>
								<p>
									ur #420 friendly smoke lounge is a fun vibes, comfortable
									space with TV’s, large speaker, complimentary refreshments,
									smoke ventilation and comfortable seating. Available 7 days a
									week for unwinding and relaxation. We also offer FREE events
									or rental of the lounge area for private or social gatherings.
									BYOB permitted, must be 21+ to enter. For more information
									visit our frequently asked questions section, stop in and see
									us, or contact us anytime.
								</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam.
								</p>
							</div>
						</div>

						<div className="col-md-6">
							<div className="whole-second-box">
								<div className="about-img about-second-img">
									<img className="img-fluid" src={about1} />
								</div>

								<div className="about-small-box">
									<h5>
										Cobus Bester <span>- Nov 29 ,2022</span>
									</h5>
									<p>
										Sed velit dignissim sodales ut eu sem. Phasellus egestas
										tellus rutrum tellus
									</p>
									<div className="stars reviews-stars">
										<i className="fa fa-star" aria-hidden="true"></i>
										<i className="fa fa-star" aria-hidden="true"></i>
										<i className="fa fa-star" aria-hidden="true"></i>
										<i className="fa fa-star" aria-hidden="true"></i>
										<i className="fa fa-star" aria-hidden="true"></i>
									</div>
									<div className=""></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
					{/* ================================================================= */}

					{/* Spining section */}
					<section className="spin-sec spin-second-sec ">
						<div className="container">
							<div className="row">
								<div className="col-md-12">
									<div className="spin-text">
										<div className="about-banner-text shop-banner-text spin-div spin-second-div">
											<div
												dangerouslySetInnerHTML={{ __html: contactBannerData }}
											></div>
											<div className="banner-button spin-btn about-contact-btn">
												<Link to="/contact-us" className="all-button">
													CONTACT US
													<img className="img-fluid" src={arrow} />{" "}
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* ========================================================== */}

					{/* About-section-Third  */}
					{/* <section className="banner-about-sec  about-third-sec inner-about-para-color">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="about-img about-third-img">
								<img className="img-fluid" src={about3} />
							</div>
						</div>
						<div className="col-md-6">
							<div className="about-banner-text">
								<h5>
									PRIVATE <span className="for-gap-in-words">MEMBERS</span>{" "}
									LOUNGE
								</h5>
								<h2>
									ABOUT orderzaza<span>.</span>
								</h2>
								<p>
									ur #420 friendly smoke lounge is a fun vibes, comfortable
									space with TV’s, large speaker, complimentary refreshments,
									smoke ventilation and comfortable seating. Available 7 days a
									week for unwinding and relaxation. We also offer FREE events
									or rental of the lounge area for private or social gatherings.
									BYOB permitted, must be 21+ to enter. For more information
									visit our frequently asked questions section, stop in and see
									us, or contact us anytime.
								</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section> */}
					{/* ================================================================= */}

					<Footer />
				</>
			) : (
				<>
					<Spinner />
				</>
			)}
		</>
	);
}

export default About;
