import React, { useState } from "react";
import { useNewsletterContentMutation } from "../store/services/NewsletterService";
import { arrow } from "../constant/Index";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Newsletter = () => {
	const [email, setEmail] = useState("");
	const [UserData, response] = useNewsletterContentMutation();
	const handleSubmit = (e) => {
		e.preventDefault();
		if (email === "") {
			toast.error("Please Enter Email Address");
		} else {
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
				const formData = new FormData();
				formData.append("email", email);
				UserData(formData);
			} else {
				toast.error("Invalid Email Address");
			}
		}
	};

	useEffect(() => {
		if (response?.isError) {
			toast.error(response?.error?.data?.errors?.email[0]);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response?.isSuccess) {
			toast.success(response?.data?.response?.data);
			setEmail("");
		}
	}, [response?.data?.response?.data]);
	return (
		<>
			<section className="newsletter-sec">
				<div className="container">
					<div className="about-banner-text become-member-head subscribe-head">
						<h5>
							SMOKE <span className="for-gap-in-words">PIPE</span> EVERYDAY
						</h5>
						<h2>
							SUBSCRIBE TO THE NEWSLETTER<span>.</span>
						</h2>
						<p>Exclusive discounts and special occasions right in your inbox</p>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="subscribe">
								<div className="newsletter-input">
									<input
										type="text"
										placeholder="Enter your email address"
										name="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
								<div className="banner-button subscribe-btn">
									{/* <Link to="/" className="all-button" onClick={handleSubmit}>
										SUBSCRIBE
										<img className="img-fluid" src={arrow} />{" "}
									</Link> */}
									<button className="all-button" onClick={handleSubmit}>
										{response?.isLoading ? "Loading..." : "SUBSCRIBE"}
										<img className="img-fluid" src={arrow} />{" "}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Newsletter;
