import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import faq from "../../assets/css/faq.css";
import PageHeader from "../../components/Pageheader";
import Threelinks from "../../components/Threelinks";
import { useFaqContentQuery } from "../../store/services/FaqService";
import Spinner from "../../utils/Loader";

function Faq() {
	const { data, isLoading } = useFaqContentQuery();
	let FaqsData = data?.response?.data;
	return (
		<>
			{!isLoading ? (
				<>
					<Header />

					<PageHeader
						pagename="FREQUENTLY ASKED QUESTIONS"
						pagepara="Below are mentioned some of the common pressing questions about our company. Feel free to contact us if you have a query not addressed here."
					/>
					{/* Faq Section */}
					<section className="faq-sec">
						<div className="container">
							<div className="row">
								<div className="col-md-12">
									<div className="faq-all">
										<div className="accordion" id="accordionExample">
											{FaqsData?.map((data, index) => {
												return (
													<div className="accordion-item" key={index}>
														<h2
															className="accordion-header"
															id={`#heading${data?.id}`}
														>
															<button
																className="accordion-button"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target={`#collapse${data?.id}`}
																aria-expanded="true"
																aria-controls={`collapse${data?.id}`}
															>
																<span>
																	<i
																		className="fa fa-plus"
																		aria-hidden="true"
																	></i>
																</span>
																<h5>{data?.question}</h5>
															</button>
														</h2>
														<div
															id={`collapse${data?.id}`}
															className={`${
																index == 0
																	? "accordion-collapse collapse show"
																	: "accordion-collapse collapse"
															}`}
															aria-labelledby={`#heading${data?.id}`}
															data-bs-parent="#accordionExample"
														>
															<div className="accordion-body">
																<div className="accord-para">
																	<p>{data?.answer}</p>
																</div>
															</div>
														</div>
													</div>
												);
											})}
											{/* <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <span>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </span>
                  <h5> How Much orderzaza lounge charge?</h5>
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="accord-para">
                    <p>
                      Sed velit dignissim sodales ut eu sem. Phasellus
                      egestas tellus rutrum tellus pe lle ntesque eu
                      tincidunt tortor aliquam. Elit eget gravida cum
                      sociis. Vitae turpis massa sed elementum tempus
                      egestas. Eu tincidunt tortor aliquam nulla facilisi
                      cras fermentum odio eu. Sed risus pretium
                      Condimentum lacinia quis vel eros donec ac. Sem et
                      tortor consequa
                    </p>
                    <p>
                      Sed velit dignissim sodales ut eu sem. Phasellus
                      egestas tellus rutrum tellus pe lle ntesque eu
                      tincidunt tortor aliquam. Elit eget gravida cum
                      sociis. Vitae turpis massa sed elementum tempus
                      egestas. Eu tincidunt tortor aliquam nulla facilisi
                      cras fermentum odio eu. Sed risus pretium
                      Condimentum lacinia quis ve
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <span>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </span>
                  <h5> How Much orderzaza lounge charge?</h5>
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="accord-para">
                    <p>
                      Sed velit dignissim sodales ut eu sem. Phasellus
                      egestas tellus rutrum tellus pe lle ntesque eu
                      tincidunt tortor aliquam. Elit eget gravida cum
                      sociis. Vitae turpis massa sed elementum tempus
                      egestas. Eu tincidunt tortor aliquam nulla facilisi
                      cras fermentum odio eu. Sed risus pretium
                      Condimentum lacinia quis vel eros donec ac. Sem et
                      tortor consequa
                    </p>
                    <p>
                      Sed velit dignissim sodales ut eu sem. Phasellus
                      egestas tellus rutrum tellus pe lle ntesque eu
                      tincidunt tortor aliquam. Elit eget gravida cum
                      sociis. Vitae turpis massa sed elementum tempus
                      egestas. Eu tincidunt tortor aliquam nulla facilisi
                      cras fermentum odio eu. Sed risus pretium
                      Condimentum lacinia quis ve
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingfourth">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefourth"
                  aria-expanded="false"
                  aria-controls="collapsefourth"
                >
                  <span>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </span>
                  <h5> How Much orderzaza lounge charge?</h5>
                </button>
              </h2>
              <div
                id="collapsefourth"
                className="accordion-collapse collapse"
                aria-labelledby="headingfourth"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="accord-para">
                    <p>
                      Sed velit dignissim sodales ut eu sem. Phasellus
                      egestas tellus rutrum tellus pe lle ntesque eu
                      tincidunt tortor aliquam. Elit eget gravida cum
                      sociis. Vitae turpis massa sed elementum tempus
                      egestas. Eu tincidunt tortor aliquam nulla facilisi
                      cras fermentum odio eu. Sed risus pretium
                      Condimentum lacinia quis vel eros donec ac. Sem et
                      tortor consequa
                    </p>
                    <p>
                      Sed velit dignissim sodales ut eu sem. Phasellus
                      egestas tellus rutrum tellus pe lle ntesque eu
                      tincidunt tortor aliquam. Elit eget gravida cum
                      sociis. Vitae turpis massa sed elementum tempus
                      egestas. Eu tincidunt tortor aliquam nulla facilisi
                      cras fermentum odio eu. Sed risus pretium
                      Condimentum lacinia quis ve
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
						<Threelinks />
					</section>

					<Footer />
				</>
			) : (
				<>
					<Spinner />
				</>
			)}
		</>
	);
}

export default Faq;
