import { createSlice } from "@reduxjs/toolkit";
const cartData = localStorage.getItem("cart");
const cartArray = cartData ? JSON.parse(cartData) : [];
function allItems(data) {
	let items = 0;
	for (let i = 0; i < data.length; i++) {
		items += data[i].quantity;
	}
	return items;
}
function calcuateTotal(data) {
	let total = 0;
	for (let i = 0; i < data.length; i++) {
		total += data[i].variation_price * data[i].quantity;
	}
	return total;
}
const cartReducers = createSlice({
	name: "cart",
	initialState: {
		cart: cartArray.length > 0 ? cartArray : [],
		items: cartArray.length > 0 ? allItems(cartArray) : 0,
		total: cartArray.length > 0 ? calcuateTotal(cartArray) : 0,
	},
	reducers: {
		addCart: (state, { payload }) => {
			state.cart.push(payload);
			state.items += 1;
			state.total += payload.variation_price * payload.quantity;
		},
		addQuantity: (state, { payload }) => {
			const find = state.cart.find((item) => item.id === payload?.id);
			if (find) {
				find.quantity += payload?.quantity;
				// state.items += 1;
				state.total += find.variation_price * payload?.quantity;
				const index = state.cart.indexOf(find);
				state.cart[index] = find;
				localStorage.setItem("cart", JSON.stringify(state.cart));
			}
		},
		incQuantity: (state, { payload }) => {
			const find = state.cart.find((item) => item.id === payload);
			if (find) {
				find.quantity += 1;
				// state.items += 1;
				state.total += find.variation_price;
				const index = state.cart.indexOf(find);
				state.cart[index] = find;
				localStorage.setItem("cart", JSON.stringify(state.cart));
			}
		},
		decQuantity: (state, { payload }) => {
			const find = state.cart.find((item) => item.id === payload);
			if (find && find.quantity > 1) {
				find.quantity -= 1;
				// state.items -= 1;
				state.total -= find.variation_price;
				const index = state.cart.indexOf(find);
				state.cart[index] = find;
				localStorage.setItem("cart", JSON.stringify(state.cart));
			}
		},
		removeItem: (state, { payload }) => {
			const find = state.cart.find(
				(item) =>
					item.id === payload?.id && item?.variation_name === payload?.name,
			);
			if (find) {
				const index = state.cart.indexOf(find);
				state.items -= 1;
				state.total -= find.variation_price * find.quantity;
				state.cart.splice(index, 1);
				localStorage.setItem("cart", JSON.stringify(state.cart));
			}
		},
		emptyCart: (state) => {
			state.cart = [];
			state.items = 0;
			state.total = 0;
			localStorage.removeItem("cart");
		},
	},
});
export const {
	addCart,
	incQuantity,
	decQuantity,
	removeItem,
	emptyCart,
	addQuantity,
} = cartReducers.actions;
export default cartReducers.reducer;
