import React from "react";
import Header from "../../components/Header";
import { call, lounge1, lounge2, lounge3, plant } from "../../constant/Index";
import lounge from "../../assets/css/lounge.css";
import Footer from "../../components/Footer";
import Threelinks from "../../components/Threelinks";
import PageHeader from "../../components/Pageheader";
import { useLoungeContentQuery } from "../../store/services/LoungeService";
import { useImageContentQuery } from "../../store/services/ImagesUrlService";
import Spinner from "../../utils/Loader";

function Loun() {
	// Config Urls Api Call
	const { data: urls, isLoading: urlLoading } = useImageContentQuery();
	let lounge_image = urls?.response?.data?.lounge_image_url;
	// Config Urls Api Call

	const { data, isLoading } = useLoungeContentQuery();
	let loungeData = data?.response?.data;
	return (
		<>
			{!isLoading ? (
				<>
					<Header />

					{/* <PageHeader pagename="orderzaza RENTAL" /> */}
					<div className="content-wrapper for-some-extra-css">
						<h5>
							SMOKE <span className="for-gap-in-words">PIPE </span>EVERYDAY
						</h5>
						<h2>
							orderzaza RENTAL
							<span>.</span>
						</h2>
					</div>

					{/* Lounge Section */}
					<section className="lounge-sec">
						<div className="container">
							{loungeData?.map((data) => {
								return (
									<>
										<div className="lounge-header" key={data?.id}>
											<h5>{data?.title}</h5>
											<p>{data?.sub_title}</p>
										</div>
										<div className="row">
											<div className="col-md-6">
												<div className="lounge-img ">
													<img
														className="img-fluid lounge-first-img"
														src={lounge_image + "/" + data?.image}
													/>
													<div className="call-us-now">
														<img className="img-fluid" src={call} />
													</div>
												</div>
											</div>

											<div className="col-md-6">
												<div className="row">
													<div className="col-md-12">
														<div className="lounge-img">
															<img
																className="img-fluid"
																src={lounge_image + "/" + data?.image_one}
															/>
														</div>
													</div>

													<div className="col-md-12">
														<div className="lounge-img">
															<img
																className="img-fluid"
																src={lounge_image + "/" + data?.image_two}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								);
							})}
						</div>
						<Threelinks />
					</section>
					{/* ================================== */}

					{/* Check and Para Section */}
					<section className="check-para-sec">
						<div className="container">
							<div className="row">
								<div className="col-md-9">
									{loungeData?.map((data) => {
										return (
											<p
												dangerouslySetInnerHTML={{ __html: data?.description }}
											/>
										);
									})}
								</div>
								<div className="col-md-3">
									<div className="plant-img">
										<img className="img-fluid" src={plant} />
									</div>
								</div>
							</div>
						</div>
					</section>

					<Footer />
				</>
			) : (
				<>
					<Spinner />
				</>
			)}
		</>
	);
}

export default Loun;
