// import { Navigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// const Public = ({ children }) => {
//     const { userToken } = useSelector((state) => state.AuthReducer);
//     return userToken ? <Navigate to="/" /> : children;
// };
// export default Public;

import {useSelector} from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
const Public = () => {
    const { userToken } = useSelector((state) => state.AuthReducer);
    return userToken ? <Navigate to="/" /> : <Outlet /> 
}
export default Public;