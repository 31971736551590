import React, { useEffect, useState } from "react";
import { capture } from "../../constant/Index";
import { useUserresetPasswordMutation } from "../../store/services/AuthServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Newpassword() {
	const navigate = useNavigate();
	let href = window.location?.search?.split("?token=")[1];
	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();
	const [ChangePasswordData, response] = useUserresetPasswordMutation(href);

	const HandleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("password", password);
		formData.append("confirm_password", confirmPassword);
		formData.append("token", href);
		ChangePasswordData(formData);
	};

	useEffect(() => {
		if (response?.isSuccess && response?.data?.message) {
			setPassword("");
			setConfirmPassword("");
			toast.success(response?.data?.message);
			navigate("/login");
		}
	}, [response.isSuccess]);

	useEffect(() => {
		if (response.isError && response?.error?.data?.errors) {
			for (let key in response?.error?.data?.errors) {
				if (response?.error?.data?.errors.hasOwnProperty(key)) {
					toast.error(response?.error?.data?.errors[key][0]);
				}
			}
		}
	}, [response?.error?.data]);

	return (
		<>
			{/* New Password Section */}
			<section className="login-sec">
				<div className="row">
					<div className="col-md-7">
						<div className="login-form-all">
							<form>
								<div className="row">
									<div className="login-head forget-password-head">
										<h2>Reset password</h2>
										<p> Please enter a new password</p>
									</div>
									<div className="col-md-12">
										<div className="form-dv-input">
											<input
												type="password"
												placeholder="New Password"
												value={password}
												onChange={(e) => setPassword(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-dv-input">
											<input
												type="password"
												placeholder="Confirm New Password"
												value={confirmPassword}
												onChange={(e) => setConfirmPassword(e.target.value)}
											/>
										</div>
									</div>

									<div className="col-md-12">
										<div className="banner-button submit-banner-btn login-btn">
											<button
												className="all-button submitButton"
												onClick={HandleSubmit}
											>
												{response?.isLoading ? "Loading..." : "SUBMIT"}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>

					<div className="col-md-5">
						<div className="login-form-img">
							<img className="img-fluid" src={capture} />
							<div className="login-form-text">
								<div className="about-banner-text login-all-text">
									<h5>SMOKE PIPE EVERYDAY</h5>
									<h2>orderzaza Smoke Shop.</h2>
									<p>
										Sed velit dignissim sodales ut eu sem. Phasellus egestas
										tellus rutrum tellus pe lle ntesque eu tincidunt tortor
										aliquam. Elit eget gravida cum sociis. Vitae turpis massa
										sed elementum tempus egestas. Eu tincidunt tortor aliquam
										nulla facilisi cras fermentum odio eu. Sed risus pretium
										Condimentum lacinia quis vel eros donec ac. Sem et tortor
										consequa
									</p>

									<p>
										Sed velit dignissim sodales ut eu sem. Phasellus egestas
										tellus rutrum tellus pe lle ntesque eu tincidunt tortor
										aliquam. Elit eget gravida cum sociis. Vitae turpis{" "}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Newpassword;
