import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageHeader from "../../components/Pageheader";
import { arrow, cartimg, checkoutimg } from "../../constant/Index";
import cart from "../../assets/css/cart.css";
import Threelinks from "../../components/Threelinks";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
	decQuantity,
	emptyCart,
	incQuantity,
	removeItem,
} from "../../store/reducers/CartrReducer";
import currency from "currency-formatter";

function Cart() {
	const [activeClass, setActiveClass] = useState(false);
	let navigate = useNavigate();
	const { cart, total } = useSelector((state) => state.CartrReducer);
	const dispatch = useDispatch();

	// const inc = (item) => {
	// 	if (item?.current_stock === item?.quantity) {
	// 		return;
	// 	}
	// 	dispatch(incQuantity(item?.id));
	// };

	const inc = (item) => {
		if (item?.variation_qty === item?.quantity) {
			toast.error(`Product quantity is increasing from available stock.`);
			setActiveClass(true);
			return;
		} else if (item?.variation_qty === 0) {
			toast.error(`Out Of Stock`);
			setActiveClass(true);
			return;
		} else {
			dispatch(incQuantity(item?.id));
			setActiveClass(false);
		}
	};

	const dec = (item) => {
		if (item?.current_stock <= 0) {
			return;
		}
		setActiveClass(false);
		dispatch(decQuantity(item?.id));
	};

	const HandleClick = (id, name) => {
		let obj = {
			id,
			name,
		};
		dispatch(removeItem(obj));
		toast.success("Your Product Has Been Removed");
	};

	return (
		<>
			<Header />
			<PageHeader pagename="YOUR CART" />

			{/* Cart section */}
			{cart?.length > 0 ? (
				<section className="cart-sec">
					<div className="container">
						<div className="row">
							<div className="col-md-8">
								<div className="Main-cart-table" style={{overflow : 'auto'}}>
									<table className="cart-table">
										<thead>
											<th></th>
											<th>PRODUCT</th>
											<th>PRICE</th>
											<th>QUANTITY</th>
											<th>SUBTOTAL</th>
										</thead>
										{cart?.map((item, index) => {
											return (
												<tbody className="t-body-all">
													<tr className="all-rows-tr">
														<td className="body-img">
															<img
																className="img-fluid"
																src={item?.thumbnail}
																style={{minWidth : '80px', objectFit : 'cover'}}
															/>
															<div
																className="body-delete-all"
																onClick={() =>
																	HandleClick(item?.id, item?.variation_name)
																}
																style={{ cursor: "pointer" }}
															>
																<i
																	className="fa fa-trash-o"
																	aria-hidden="true"
																></i>
															</div>
														</td>

														<td className="for-back-color">
															{item?.name}
															<br />({item?.variation_name})
														</td>
														<td className="for-back-color">
															{currency.format(item?.variation_price, {
																code: "USD",
															})}
														</td>
														<td className="for-back-color">
															<div className="quantity-btn-all">
																<button onClick={() => dec(item)}>
																	<i
																		className="fa fa-minus"
																		aria-hidden="true"
																	></i>
																</button>
																<input value={item?.quantity} />
																<button
																	onClick={() => inc(item)}
																	disabled={activeClass ? "true" : ""}
																>
																	<i
																		className={
																			activeClass
																				? `fa fa-plus cancelbtns`
																				: `fa fa-plus`
																		}
																		aria-hidden="true"
																	></i>
																</button>
															</div>
														</td>
														<td className="for-back-color">
															{currency.format(
																item?.variation_price * item?.quantity,
																{
																	code: "USD",
																},
															)}
														</td>
													</tr>
												</tbody>
											);
										})}
									</table>
								</div>
							</div>

							<div className="col-md-4">
								<div className="product-detail-all cart-detail-all ">
									<div className="product-head">
										<h5>ORDER SUMMARY</h5>
									</div>

									<div className="all-total cart-total-all">
										<div className="total">
											<p className="for-total-css">Total :</p>
											<p className="for-total-green">
												{currency.format(total, {
													code: "USD",
												})}
											</p>
										</div>

										<div className="total order-hotel-all">
											<p className="for-total-css">Order Total : </p>
											<p className="for-total-green">
												{currency.format(total, {
													code: "USD",
												})}
											</p>
										</div>
									</div>

									<div className="row">
										<div className="col-md-6">
											<div className="banner-button login-btn cart-btn c-btn1 setArrow">
												<Link to="/shop" className="all-button">
													CONTINUE SHOPPING
													<img className="img-fluid" src={arrow} />
												</Link>
											</div>
										</div>

										<div className="col-md-6">
											<div className="banner-button login-btn cart-btn c-btn2">
												<Link to="/check-out" className="all-button">
													CHECKOUT <img className="img-fluid" src={arrow} />{" "}
												</Link>
											</div>
										</div>
									</div>
								</div>

								{/* <div className="product-detail-all product-copun">
								<div className="product-head coupon-head">
									<h5>APPLY COUPON</h5>
									<p>If you have a coupon code, please apply it below.</p>
								</div>
								<div className="coupon-input">
									<input type="text" />
									<div className="coupon-dv">
										<div className="banner-button  cart-coupon-btn">
											<Link className="all-button">
												APPLY COUPON <img className="img-fluid" src={arrow} />{" "}
											</Link>
										</div>
									</div>
								</div>
							</div> */}
							</div>
						</div>
					</div>
				</section>
			) : (
				<h2 className="center_thistext">Your Card is Empty </h2>
			)}
			<Threelinks />
			<Footer />
		</>
	);
}

export default Cart;
