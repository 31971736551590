import React from "react";
import events from "../../assets/css/events.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { eventsinner } from "../../constant/Index";
import { useEventsContentGetQuery } from "../../store/services/EventServices";
import { useParams } from "react-router-dom";
import { useImageContentQuery } from "../../store/services/ImagesUrlService";
import Spinner from "../../utils/Loader";

function Eventsdetail() {
	const { slug } = useParams();
	const { data, isLoading } = useEventsContentGetQuery(slug);
	const { data: urls, isLoading: urlLoading } = useImageContentQuery();
	let event_details = urls?.response?.data?.events_image_url;
	const EventDetails = data?.response?.data;
	return !isLoading ? (
		<>
			<Header />
			{/* Event Detail Section */}
			<section className="Event-Detail-Section">
				<div className="container">
					<div className="about-banner-text ">
						{/* <h5>SMOKE <span className="for-gap-in-words">PIPE</span> EVERYDAY</h5> */}
						<h5>{EventDetails?.title}</h5>
						<h2>{EventDetails?.sub_title}</h2>
					</div>

					<div className="row">
						<div className="col-md-12">
							<div className="event-detail-all">
								<div className="events-img-all">
									<img
										className="img-fluid"
										src={`${event_details}/${EventDetails?.image}`}
									/>
									<div className="special-tag">
										<p>Special offers</p>
									</div>
								</div>

								<div className="event-para">
									<p>{EventDetails?.description}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Threelinks />
			{/* ================================================== */}

			<Footer />
		</>
	) : (
		<Spinner />
	);
}

export default Eventsdetail;
