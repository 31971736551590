import React, { useState } from "react";
import { useLocation, NavLink, Link, useNavigate } from "react-router-dom";
import { logo, bag, profileimg, plant, dummyImage } from "../constant/Index";
import { useSettingContentQuery } from "../store/services/SiteSettingService";
import {
	useCoreimageContentQuery,
	useImageContentQuery,
} from "../store/services/ImagesUrlService";
import { useDispatch, useSelector } from "react-redux";
import { logout, setSearchValue } from "../store/reducers/AuthReducer";
import { useEffect } from "react";
import { useUsermeInfoQuery } from "../store/services/AuthServices";

function Header() {
	// Profile Image Api Call
	const { data: profileImage, isLoading: profileLoading } =
		useUsermeInfoQuery();
	// let headerProfile = profileImage?.response?.data?.profile_pic_url;
	const headerProfile = profileImage?.response?.data;

	// Profile Image Api Call

	// Config Url Api Call
	const { data: logoData, isLoading: logoLoading } = useImageContentQuery();
	let Imageurl = logoData?.response?.data?.company_logo_url;
	// Config Url Api Call

	// Info Api Call Api Call
	const { data: infoData, isLoading: infoLoading } = useCoreimageContentQuery();
	let headerLogo = infoData?.response?.data[2]?.value;
	// Info Api Call Api Call

	const { cart } = useSelector((state) => state.CartrReducer);
	const { userToken } = useSelector((state) => state.AuthReducer);
	const [search, setSearchBar] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();

	//assigning location variable
	const location = useLocation();
	const [view, setView] = useState(false);

	const searchBarData = (e) => {
		e.preventDefault();
		let locations = window.location.href;
		if (search !== "") {
			if (locations.split("/")[3] === "shop") {
				navigate(`/shop?slug=${search}`);
			} else {
				navigate(`/shop?slug=${search}`);
			}
		} else {
			navigate(`/shop`);
		}
	};
	const clearsearchBarData = () => {
		if (search !== "") {
			setSearchBar("");
			navigate(`/shop`);
		}
	};
	//destructuring pathname from location
	const { pathname } = location;

	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split("/");

	const HandleLogout = (e) => {
		e.preventDefault();
		dispatch(logout());
		navigate("/");
	};

	return (
		<>
			<section className="header-sec">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-2">
							<div className="web-logo">
								<Link to="/">
									<img
										className="img-fluid"
										src={Imageurl + "/" + headerLogo}
									></img>
								</Link>
							</div>
						</div>
						<div className="col-md-6">
							<div className="nav-home">
								<ul className="home-ul">
									<li className={splitLocation[1] === "" ? "active" : ""}>
										{" "}
										<NavLink to="/">home</NavLink>
									</li>
									<li className={splitLocation[1] === "about" ? "active" : ""}>
										{" "}
										<NavLink to="/events">events & specials</NavLink>
									</li>
									<li>
										{" "}
										<NavLink to="/shop">shop</NavLink>
									</li>
									<li>
										{" "}
										<NavLink to="/lounge-rental">lounge rental</NavLink>
									</li>
									<li>
										{" "}
										<NavLink to="/membership">membership</NavLink>
									</li>
									<li>
										{" "}
										<NavLink to="/locations">locations</NavLink>
									</li>
									<li>
										{" "}
										<NavLink to="/faq">faq</NavLink>
									</li>
									<li>
										{" "}
										<NavLink to="/contact-us">contact us</NavLink>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-4">
							<div className="all-for-flex">
								<div className="search">
									<input
										type="text"
										placeholder="SEARCH"
										value={search}
										onChange={(e) => setSearchBar(e.target.value)}
									/>
									{search && (
										<i
											className="fa fa-times endIcons"
											aria-hidden="true"
											onClick={clearsearchBarData}
										></i>
									)}
									<i
										className="fa fa-search"
										aria-hidden="true"
										onClick={searchBarData}
									></i>
									<Link to="/cart">
										{" "}
										<img className="img-fluid" src={bag} />
									</Link>
									<div className="shopping-bag-icon">
										<p>{cart?.length <= 0 ? 0 : cart?.length}</p>
									</div>
								</div>
								{userToken ? (
									<div className="profile-on-header">
										<button className="logoutButton" onClick={HandleLogout}>
											Logout
										</button>
										{!headerProfile?.profile_pic ? (
											<img
												className="img-fluid"
												src={dummyImage}
												onClick={() => navigate("/profile")}
												style={{
													borderRadius: "50%",
													height: "50px",
													width: "50px",
													marginLeft: "20px",
													cursor: "pointer",
													objectFit: "cover",
												}}
											/>
										) : (
											<img
												src={headerProfile?.profile_pic_url}
												onClick={() => navigate("/profile")}
												style={{
													borderRadius: "50%",
													height: "50px",
													width: "50px",
													marginLeft: "20px",
													cursor: "pointer",
													objectFit: "cover",
												}}
											/>
										)}
									</div>
								) : (
									<div className="profile-on-header">
										<Link to="/login">Login</Link>
									</div>
								)}
							</div>
							<div className="canvas_btn " onClick={() => setView(true)}>
								{/* <Link to="/">
					<img className="img-fluid" src={logo}></img>
				</Link> */}
								<i className="fa fa-bars" aria-hidden="true"></i>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ========================================= */}

			{/* <!-- Mobile Header Start Here --> */}
			<div className={view ? "mobile_header show" : "mobile_header "}>
				<div className="cancel" onClick={() => setView(false)}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="25"
						height="25"
						fill="#000"
						className="bi bi-x"
						viewBox="0 0 16 16"
					>
						<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
					</svg>
				</div>

				<ul className="mobile_menus">
					<li className="menu-items">
						<Link to="/"> Home</Link>
					</li>
					<li className="menu-items">
						<Link to="/events"> Events & Specials</Link>
					</li>
					<li className="menu-items">
						<Link to="/shop"> shop</Link>
					</li>
					<li className="menu-items">
						<Link to="/lounge-rental"> lounge rental</Link>
					</li>
					<li className="menu-items">
						<Link to="/membership"> memberships</Link>
					</li>
					<li className="menu-items">
						<Link to="/locations"> locations</Link>
					</li>
					<li className="menu-items">
						<Link to="/faq"> faq</Link>
					</li>
					<li className="menu-items">
						<Link to="/contact-us"> contact us</Link>
					</li>

					<li className="menu-items">
						<Link to="/cart"> Cart</Link>
					</li>

					<div className="all-for-flex">
						<div className="search">
							<input
								type="text"
								placeholder="SEARCH"
								value={search}
								onChange={(e) => setSearchBar(e.target.value)}
							/>
							{search && (
								<i
									className="fa fa-times endIcons"
									aria-hidden="true"
									onClick={clearsearchBarData}
								></i>
							)}
							<i
								className="fa fa-search"
								aria-hidden="true"
								onClick={searchBarData}
							></i>
							<Link to="/cart" className="cart__wrapper">
								{" "}
								<img className="img-fluid" src={bag} />
								<div className="shopping-bag-icon">
									<p>{cart?.length <= 0 ? 0 : cart?.length}</p>
								</div>
							</Link>
						</div>
					</div>

					{userToken ? (
						<div className="profile-on-header">
							<button className="logoutButton" onClick={HandleLogout}>
								Logout
							</button>
							{!headerProfile?.profile_pic ? (
								<img
									className="img-fluid"
									src={dummyImage}
									onClick={() => navigate("/profile")}
									style={{
										borderRadius: "50%",
										height: "50px",
										width: "50px",
										marginLeft: "20px",
										cursor: "pointer",
										objectFit: "cover",
									}}
								/>
							) : (
								<img
									src={headerProfile?.profile_pic_url}
									onClick={() => navigate("/profile")}
									style={{
										borderRadius: "50%",
										height: "50px",
										width: "50px",
										marginLeft: "20px",
										cursor: "pointer",
										objectFit: "cover",
									}}
								/>
							)}

							{/* <Link to="/">Logout</Link> */}
							{/* <Link to="/login">
										<div className="profile-text">
											<h4>WELCOME BACK</h4>
											<h5>JOHN DOE</h5>
										</div>
									</Link>
									<div className="profile-img">
										<img className="img-fluid" src={profileimg} />
									</div> */}
						</div>
					) : (
						<div className="profile-on-header">
							<Link to="/login" className="logoutButton">Login</Link>
							{/* <Link to="/login">
										<div className="profile-text">
											<h4>WELCOME BACK</h4>
											<h5>JOHN DOE</h5>
										</div>
									</Link>
									<div className="profile-img">
										<img className="img-fluid" src={profileimg} />
									</div> */}
						</div>
					)}
					{/* <li className="for-responsive-login-btn">
						{" "}
						<div className="profile-on-header">
							
							<div className="profile-img">
								<img className="img-fluid" src={profileimg} />
							</div>
						</div>
					</li> */}
					<div className="plant-img responsive-plant-img">
						<img className="img-fluid" src={plant} />
					</div>
				</ul>
			</div>
			{/* =================================== */}
		</>
	);
}

export default Header;
