import React from "react";
import PageHeader from "../../components/Pageheader";
import events from "../../assets/css/events.css";
import {
	event1,
	event2,
	event3,
	greenclock,
	special1,
	special2,
	special3,
	special4,
	whiteclock,
} from "../../constant/Index";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { useEventsContentQuery } from "../../store/services/EventServices";
import { useImageContentQuery } from "../../store/services/ImagesUrlService";
import Spinner from "../../utils/Loader";
import { useSettingContentQuery } from "../../store/services/SiteSettingService";

function Events() {
	// Config Urls Api Call
	const { data: urls, isLoading: urlLoading } = useImageContentQuery();
	const { data: Eventsurls, isLoading: eventLoading } =
		useSettingContentQuery();
	let about_image = urls?.response?.data?.events_image_url;
	let event_image = Eventsurls?.response?.data?.[0]?.events_special;
	let navigate = useNavigate();

	// Config Urls Api Call
	const { data, isLoading } = useEventsContentQuery();
	const EventDatas = data?.response?.data;

	return (
		<>
			{!isLoading ? (
				<>
					<Header />
					<PageHeader
						pageUrl="event"
						pagename="EVENTS & SPECIALS"
						pagepara={event_image}
					/>
					{/* Event-section */}
					{/* <section className="event-section">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="event-img">
								<img className="img-fluid for-adjust" src={event1} />
								<div className="event-text">
									<h3>orderzaza Smoke Shop</h3>
									<h5>
										<span className="event-clock-img">
											<img className="img-fluid" src={whiteclock} />
										</span>
										18 MAY 2022
									</h5>
									<p>
										Sed velit dignissim sodales ut eu sem. Phasellus egestas
										tellus rutrum tellus pe lle ntesque eu tincidunt tortor
										aliquam. Elit eget gravida cum sociis. Vitae turpis massa
										sed elementum tempus egestas. Eu tincidunt tortor aliquam
										nulla facilisi cras fermentum odio eu. Sed risus{" "}
									</p>
									<Link>READ MORE</Link>
								</div>
								<div className="special-tag">
									<p>Special offers</p>
								</div>
							</div>
						</div>

						<div className="col-md-6 for-background-color">
							<div className="row for-align-setting">
								<div className="col-md-7">
									<div className="members-t">
										<h3>orderzaza Smoke Shop</h3>
										<h5>
											<span className="member-clock-img">
												<img className="img-fluid" src={greenclock} />
											</span>
											18 MAY 2022
										</h5>
										<p>
											Sed velit dignissim sodales ut eu sem. Phasellus egestas
											tellus rutrum tellus pe lle ntesque eu tincidunt tortor
											aliquam. Elit eget gravida cum sociis. Vitae turpis massa
											sed elementum tempus egestas. Eu tincidunt tortor aliquam
											nulla facilisi cras fermentum odio eu. Sed risus{" "}
										</p>
										<Link>READ MORE</Link>
									</div>
								</div>
								<div className="col-md-5">
									<div className="member-t-img">
										<img className="img-flui" src={event2} />
										<div className="member-t-tag">
											<p>members</p>
										</div>
									</div>
								</div>
							</div>
							<div className="row for-align-setting">
								<div className="col-md-7">
									<div className="members-t">
										<h3>orderzaza Smoke Shop</h3>
										<h5>
											<span className="member-clock-img">
												<img className="img-fluid" src={greenclock} />
											</span>
											18 MAY 2022
										</h5>
										<p>
											Sed velit dignissim sodales ut eu sem. Phasellus egestas
											tellus rutrum tellus pe lle ntesque eu tincidunt tortor
											aliquam. Elit eget gravida cum sociis. Vitae turpis massa
											sed elementum tempus egestas. Eu tincidunt tortor aliquam
											nulla facilisi cras fermentum odio eu. Sed risus{" "}
										</p>
										<Link>READ MORE</Link>
									</div>
								</div>
								<div className="col-md-5">
									<div className="member-t-img">
										<img className="img-flui" src={event3} />
										<div className="member-t-tag">
											<p>members</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
<<<<<<< HEAD
			</section>

=======
				<Threelinks />
			</section> */}
					{/* ================================= */}
					{/* Event Special Section */}
					<section className="event-special-sec">
						<div className="container">
							<div className="row">
								<div className="col-md-12">
									<div className="row">
										{EventDatas?.map((data, index) => {
											return (
												<div
													className="col-md-3"
													onClick={() =>
														navigate(`/events-detail/${data?.slug}`)
													}
													style={{ cursor: "pointer" }}
												>
													<div className="event-special-all">
														<div className="event-img">
															<img
																className="img-fluid"
																src={`${about_image}/${data?.image}`}
															/>
															<div className="mem-t">
																<h3 className="line-clamp3">{data?.title}</h3>
																<h5>
																	<span className="member-clock-img">
																		<img
																			className="img-fluid"
																			src={greenclock}
																		/>
																	</span>
																	{data?.event_date}
																</h5>
																<p className="line-clamp">
																	{data?.description}
																</p>
																<Link to={`/events-detail/${data?.slug}`}>
																	READ MORE
																</Link>
															</div>
														</div>
														<div className="special-event-tag">
															<p>Special offers</p>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</section>
					<Footer />
				</>
			) : (
				<>
					<Spinner />
				</>
			)}
		</>
	);
}

export default Events;
