import { useDispatch } from "react-redux";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { membercardNoneData } from "../../store/reducers/MembershipReducer";
import { toast } from "react-toastify";

export default function MembershipPayment({ memberData, Imageurl }) {
	const dispatch = useDispatch();
	return (
		<PaymentForm
			applicationId="sandbox-sq0idb-e1ww-hHoy1u3sGz_3AP53g"
			locationId="LQ19BFVB5Z6A5"
			createPaymentRequest={() => ({
				countryCode: "US",
				currencyCode: "USD",
				lineItems: [
					{
						amount: memberData?.price,
						label: memberData?.title,
						id: "SKU-12345",
						pending: true,
						productUrl: `(${Imageurl}/${memberData?.image})`,
					},
				],
				requestBillingContact: false,
				requestShippingContact: false,
				total: {
					amount: memberData?.price,
					label: "Total",
				},
			})}
			cardTokenizeResponseReceived={async (token) => {
				dispatch(membercardNoneData(token?.token));
				toast.success(
					"Your card details has been saved, please confirm to place order.",
				);
			}}
		>
			<CreditCard render={(Button) => <Button>Save</Button>} />
		</PaymentForm>
	);
}
