import React from "react";
import "../assets/css/Spinner2.css";

function SmallSpinner() {
	return (
		<div className="loader-fulls">
			<div className="spinner-border" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
}

export default SmallSpinner;
