import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Url, GalleryEndPoint } from "../../Type/BaseUrl";

const Gallery = createApi({
	reducerPath: "gallery",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
		prepareHeaders: (headers, { getState }) => {
			headers.set("Access-Control-Allow-Origin", "*");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			galleryContent: builder.query({
				query: () => {
					return {
						url: GalleryEndPoint,
						method: "Get",
					};
				},
			}),
		};
	},
});

export const { useGalleryContentQuery } = Gallery;
export default Gallery;
