import React from "react";
import { gallery1, gallery2, gallery3, smallarrow } from "../constant/Index";
import { useGalleryContentQuery } from "../store/services/GalleryService";
import { useImageContentQuery } from "../store/services/ImagesUrlService";
const Gallery = () => {
	const { data: urls, isLoading: urlLoading } = useImageContentQuery();
	let gallery_url = urls?.response?.data?.gallery_image_url;
	const { data, isLoading } = useGalleryContentQuery();
	let galleryImages = data?.response?.data;
	return (
		<>
			<section className="gallery-sec">
				<div className="container">
					<div className="about-banner-text shop-banner-text galler-head">
						<h5>
							SMOKE <span className="for-gap-in-words">PIPE</span> EVERYDAY
						</h5>
						<h2>
							OUR GALLERY<span>.</span>
						</h2>
					</div>
					<div className="gallery_box">
						{galleryImages?.map((data) => {
							return (
								<div className="gallery-all" key={data?.id}>
									<div className="gallery-img">
										<img
											className="img-fluid"
											src={gallery_url + "/" + data?.image}
										/>
									</div>
									{/* <div className="small-arrow-img">
										<img className="img-fluid" src={smallarrow} />
									</div> */}
								</div>
							);
						})}
					</div>
				</div>
			</section>
		</>
	);
};

export default Gallery;
