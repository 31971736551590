import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { arrow } from "../../constant/Index";
import profile from "../../assets/css/profile.css";
import Sidebar from "./Sidebar";
import Threelinks from "../../components/Threelinks";
import { useAuthNewPasswordMutation } from "../../store/services/AuthServices";
import { toast } from "react-toastify";

function Changepassword() {
	const navigate = useNavigate();
	const [current_password, setCurrent_password] = useState();
	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();
	const [ChangePasswordData, response] = useAuthNewPasswordMutation();

	const HandleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("current_password", current_password);
		formData.append("password", password);
		formData.append("confirm_password", confirmPassword);
		ChangePasswordData(formData);
	};

	useEffect(() => {
		if (response?.isSuccess) {
			toast(response?.data?.message);
			navigate("/profile");
		}
	}, [response.isSuccess]);

	useEffect(() => {
		if (response.isError && response?.error?.data?.errors) {
			for (let key in response?.error?.data?.errors) {
				if (response?.error?.data?.errors.hasOwnProperty(key)) {
					toast.error(response?.error?.data?.errors[key][0]);
				}
			}
		}
	}, [response?.error?.data]);

	return (
		<>
			<Header />

			{/* Change Password */}
			<section className="change-password-sec my-profile-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>
						<div className="col-md-9 profile-all">
							<div className="change-password-form">
								<form>
									<div className="row">
										<div className="col-md-12">
											<div className="my-profile-head-and-btn">
												<h3>CHANGE PASSWORD</h3>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<label>Current Password</label>
											<div className="change-password-input">
												<input
													type="text"
													name="current_password"
													value={current_password}
													onChange={(e) => setCurrent_password(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<label>New Password</label>
											<div className="change-password-input">
												<input
													type="text"
													name="password"
													value={password}
													onChange={(e) => setPassword(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<label>Re-Enter New Password</label>
											<div className="change-password-input">
												<input
													type="text"
													name="confirmPassword"
													value={confirmPassword}
													onChange={(e) => setConfirmPassword(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="row">
												<div className="col-md-4">
													<div className="banner-button login-btn change-password-btn">
														<button
															className="all-button submitButton"
															onClick={() => navigate("/profile")}
														>
															CANCEL <img className="img-fluid" src={arrow} />{" "}
														</button>
													</div>
												</div>
												<div className="col-md-4">
													<div className="banner-button login-btn change-password-btn">
														<button
															onClick={HandleSubmit}
															className="all-button submitButton"
														>
															{response?.isLoading
																? "Loading..."
																: "SAVE PASSWORD"}
															<img className="img-fluid" src={arrow} />{" "}
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<Threelinks />
			</section>

			<Footer />
		</>
	);
}

export default Changepassword;
