import { configureStore } from "@reduxjs/toolkit";
import AuthServices from "./services/AuthServices";
import SiteSetting from "./services/SiteSettingService";
import HomeAbout from "./services/HomeAboutService";
import Gallery from "./services/GalleryService";
import FaqService from "./services/FaqService";
import NewsletterService from "./services/NewsletterService";
import ContactService from "./services/ContactService";
import ImageUrlService from "./services/ImagesUrlService";
import MembershipService from "./services/Membership";
import AuthReducer from "./reducers/AuthReducer";
import CartrReducer from "./reducers/CartrReducer";
import MembershipReducer from "./reducers/MembershipReducer";
import AddressServices from "./services/AddAddressServices";
import Products from "./services/ProductService";
import SocialService from "./services/SocialService";
import LocationService from "./services/LocationService";
import LoungeService from "./services/LoungeService";
import EventServices from "./services/EventServices";
import PlaceOrderService from "./services/PlaceOrderService";

const Index = configureStore({
	reducer: {
		[AuthServices.reducerPath]: AuthServices.reducer,
		[SiteSetting.reducerPath]: SiteSetting.reducer,
		[HomeAbout.reducerPath]: HomeAbout.reducer,
		[Gallery.reducerPath]: Gallery.reducer,
		[FaqService.reducerPath]: FaqService.reducer,
		[NewsletterService.reducerPath]: NewsletterService.reducer,
		[ContactService.reducerPath]: ContactService.reducer,
		[ImageUrlService.reducerPath]: ImageUrlService.reducer,
		[MembershipService.reducerPath]: MembershipService.reducer,
		[AddressServices.reducerPath]: AddressServices.reducer,
		[Products.reducerPath]: Products.reducer,
		[SocialService.reducerPath]: SocialService.reducer,
		[LocationService.reducerPath]: LocationService.reducer,
		[LoungeService.reducerPath]: LoungeService.reducer,
		[EventServices.reducerPath]: EventServices.reducer,
		[PlaceOrderService.reducerPath]: PlaceOrderService.reducer,
		AuthReducer: AuthReducer,
		CartrReducer: CartrReducer,
		MembershipReducer: MembershipReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat([
			AuthServices.middleware,
			EventServices.middleware,
			SiteSetting.middleware,
			HomeAbout.middleware,
			Gallery.middleware,
			FaqService.middleware,
			NewsletterService.middleware,
			ContactService.middleware,
			ImageUrlService.middleware,
			MembershipService.middleware,
			AddressServices.middleware,
			Products.middleware,
			SocialService.middleware,
			LocationService.middleware,
			LoungeService.middleware,
			PlaceOrderService.middleware,
		]),
});

export default Index;
