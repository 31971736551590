import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { plant } from "../../constant/Index";
import '../../assets/css/notFound.css'
const NotFound = () => {
  return (
    <>
      <Header />
      <main className="not__found-wrap">
       <div className="about-banner-text">
        <h1>404 Not Found</h1>
       <h2>The page you’re looking for can’t be found.</h2>
       </div>
        <img src={plant} alt="Pipe Paradise" className="img-fluid"/>
      </main>
      <Footer />
    </>
  );
};

export default NotFound;
