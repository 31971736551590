import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageHeader from "../../components/Pageheader";
import Threelinks from "../../components/Threelinks";
import currency from "currency-formatter";
import {
	arrow2,
	blackflower,
	shop1,
	shop2,
	shop3,
	shop4,
	shop5,
	shop6,
} from "../../constant/Index";
import {
	useGetAllCategorysQuery,
	useProductContentQuery,
} from "../../store/services/ProductService";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { addCart, addQuantity } from "../../store/reducers/CartrReducer";
import { useImageContentQuery } from "../../store/services/ImagesUrlService";
import Spinner from "../../utils/Loader";
import SmallSpinner from "../../utils/smallSpinner";

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Shop({
	paginationStatus = true,
	headers = true,
	headingData = true,
	footerData = true,
	setlength = 9,
}) {
	// Config Urls Api Call
	// const { data: urls, loadData: urlLoading } = useImageContentQuery();
	// let shop_image = urls?.response?.data?.shop_image_url;
	// Config Urls Api Call
	// const { getId, response } = useProductDetailQuery();
	const [detailId, setDetailId] = useState();
	const [variation, setVariation] = useState();
	const { userToken } = useSelector((item) => item.AuthReducer);
	const navigate = useNavigate();
	const [count, setCount] = useState(1);
	const dispatch = useDispatch();
	let query = useQuery();
	const [currentPage, setCurrentPage] = useState(1);
	const [catergoryId, setCategoryId] = useState(null);
	let Data = {
		page: currentPage,
		category_id: catergoryId,
		search: query.get("slug"),
		featured: 0,
	};
	const { data, isLoading: loadData, error } = useProductContentQuery(Data);
	const { data: categorys, isLoading: load } = useGetAllCategorysQuery();
	let ShopData = data?.response?.data;
	let CategoriesData = categorys?.response?.data;

	const HandleClick = (slug) => {
		navigate(`/shop-detail/${slug}`);
	};

	const HandleCategory = (id) => {
		setCategoryId(id);
	};

	// useEffect(() => {
	// 	return (() => {
	// 		dispatch(setSearchValue(''))
	// 	})
	// }, [])

	const pages = Math.ceil(ShopData?.total_size / ShopData?.limit);

	const handlePageClick = async (data) => {
		setCurrentPage(data?.selected + 1);
	};

	// const navigate("/")em) => {
	// 	// if (!userToken) {
	// 	// 	return toast.error("Please Login");
	// 	// }

	// 	if (variation?.qty === 0) {
	// 		toast.error(`${item?.name} Out Of Stock`);
	// 		return;
	// 	}

	// 	if (variation === undefined) {
	// 		toast.error(`Please Select Gram Variation`);
	// 		navigate(`/shop-detail/${item?.id}`);
	// 		return;
	// 	}
	// 	const cart = localStorage.getItem("cart");
	// 	const cartItems = cart ? JSON.parse(cart) : [];
	// 	const checkItem = cartItems.find((items) => items.id === item.id);
	// 	if (!checkItem) {
	// 		let MyItem2 = Object.assign({}, item, {
	// 			quantity: count,
	// 			variation_price: variation?.price,
	// 		});
	// 		dispatch(addCart(MyItem2));
	// 		cartItems.push(MyItem2);
	// 		toast.success(`${item.name} is add in cart`);
	// 		localStorage.setItem("cart", JSON.stringify(cartItems));
	// 	} else {
	// 		dispatch(addQuantity(item?.id));
	// 		toast.success(`${item.name} is add in cart`);
	// 		return;
	// 	}
	// 	setVariation();
	// };

	return (
		<>
			{!load ? (
				<>
					{headers === true && <Header />}

					{headingData === true && <PageHeader pagename="SHOP orderzaza" />}

					{/* Smoke shop section */}
					{headers === true && <Threelinks />}
					<section className="shop-inner-page-sec featured-products">
						<div className="container">
							<div className="row">
								<div className="col-md-3">
									<div className="shop-category">
										<ul className="shop-category-ul all-inner-shop-page">
											<div className="shop-cate-div">
												<h3>CATEGORIES</h3>
											</div>
											{CategoriesData?.length >= 1 &&
												CategoriesData?.map((item, index) => {
													return (
														<li
															key={index + 1}
															onClick={() => HandleCategory(item?.id)}
														>
															<Link
																className={
																	catergoryId === item?.id ? "activesdsdsd" : ""
																}
															>
																<span>
																	<img
																		className="img-fluid"
																		src={blackflower}
																	/>
																</span>{" "}
																{item?.name}
															</Link>
														</li>
													);
												})}

											{/* <li className="for-link-flex">
							<Link>
								<span>
									<img className="img-fluid" src={blackflower} />
								</span>{" "}
								Hybrids Flowers{" "}
							</Link>
							<p>SAVE %25</p>
						</li> */}
										</ul>
									</div>
								</div>
								<div
									className={`${
										error?.data?.errors ? "col-md-9 product_data" : "col-md-9"
									}`}
								>
									<div className="row">
										{loadData ? (
											<SmallSpinner />
										) : !error?.data?.errors ? (
											ShopData?.products?.map((data, index) => {
												return (
													setlength &&
													index < setlength && (
														<div className="col-md-4" key={index + 1}>
															<div className="all-shop-product-div shop-inner-dv">
																<div
																	className="shop-img"
																	onClick={() => HandleClick(data?.slug)}
																>
																	<img
																		className="img-fluid"
																		src={data?.thumbnail}
																	></img>
																</div>
																<h2 title={data?.name} className="line-clamp1">
																	{data?.name}
																</h2>
																{/* <p>{data?.para}</p> */}
																<p
																	className="line-clamp"
																	dangerouslySetInnerHTML={{
																		__html: data?.details,
																	}}
																></p>

																<h5>
																	USD{" "}
																	{currency.format(
																		variation != undefined
																			? variation?.price
																			: data?.unit_price,
																		{
																			code: "USD",
																		},
																	)}
																	<span className="small-data">
																		THC : ({data?.thc ? data?.thc : "0"}%)
																	</span>
																</h5>
																<div className="sevev-dis">
																	{/* <div className="variation-input">
																	{data?.variant_array?.map((item, index) => {
																		return (
																			<>
																				<label
																					className="variation-container"
																					onClick={() => setVariation(item)}
																				>
																					<input
																						className="form-check-input mt-0"
																						type="radio"
																						hidden
																						name="variaInput"
																						aria-label="Radio button for following text input"
																					/>
																					<span
																						className={
																							variation === undefined
																								? ""
																								: "checkmark"
																						}
																					>
																						<h4>{`${item?.type}G`}</h4>
																					</span>
																				</label>
																			</>
																		);
																	})}
																</div> */}
																</div>
																<div className="Link-all">
																	<button
																		onClick={() =>
																			navigate(`/shop-detail/${data?.slug}`)
																		}
																		className="setColor"
																	>
																		View Detail{" "}
																		<img className="img-fluid" src={arrow2} />
																	</button>
																</div>
															</div>
														</div>
													)
												);
											})
										) : (
											<div className="">
												<h3 className="not-found-txt">Product Not Found</h3>
											</div>
										)}
									</div>
								</div>
							</div>
							{paginationStatus === true && !error?.data?.errors && (
								<div className="row">
									<div className="col-lg-12">
										<div className="pagination-wrapper">
											<ReactPaginate
												previousLabel="<"
												nextLabel=">"
												breakLabel={"..."}
												pageCount={pages}
												marginPagesDisplayed={2}
												pageRangeDisplayed={3}
												onPageChange={handlePageClick}
												containerClassName={"pagination justify-content-end"}
												pageClassName={"page-item"}
												pageLinkClassName={"page-link"}
												previousClassName={"page-item"}
												previousLinkClassName={"page-link"}
												nextClassName={"page-item"}
												nextLinkClassName={"page-link"}
												breakClassName={"page-item"}
												breakLinkClassName={"page-link"}
												activeClassName={"active"}
											/>
										</div>
									</div>
								</div>
							)}
						</div>
					</section>
					{/* ================================================== */}
					{footerData === true && <Footer />}
				</>
			) : (
				<>
					<Spinner />
				</>
			)}
		</>
	);
}

export default Shop;
