import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const AuthServices = createApi({
	reducerPath: "auth",
	tagTypes: "editInfo",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://pipeparadise.developer-ourbase-camp.com/api/v1",
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			headers.set("Access-Control-Allow-Origin", "*");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			authRegister: builder.mutation({
				query: (registerData) => {
					return {
						url: "/auth/register",
						method: "POST",
						body: registerData,
					};
				},
			}),
			authLogin: builder.mutation({
				query: (loginData) => {
					return {
						url: "/auth/login",
						method: "POST",
						body: loginData,
					};
				},
			}),
			authForget: builder.mutation({
				query: (forgetData) => {
					return {
						url: "/auth/forgot-password",
						method: "POST",
						body: forgetData,
					};
				},
			}),
			authNewPassword: builder.mutation({
				query: (newPasswordData) => {
					return {
						url: "/auth/change-loginpassword",
						method: "POST",
						body: newPasswordData,
					};
				},
			}),
			usermeInfo: builder.query({
				query: () => {
					return {
						url: "/customer/info",
						method: "GET",
					};
				},
				providesTags: ["editInfo"],
			}),
			usereditInfo: builder.mutation({
				query: (newUserData) => {
					return {
						url: "/customer/update-profile",
						method: "POST",
						body: newUserData,
					};
				},
				invalidatesTags: ["editInfo"],
			}),
			usereditProfile: builder.mutation({
				query: (newUserData) => {
					return {
						url: "/customer/update-profile-pic",
						method: "POST",
						body: newUserData,
					};
				},
				invalidatesTags: ["editInfo"],
			}),
			usereditPassword: builder.mutation({
				query: (newUserData) => {
					return {
						url: "/customer/update-profile",
						method: "POST",
						body: newUserData,
					};
				},
			}),
			userresetPassword: builder.mutation({
				query: (newData) => {
					return {
						url: "/auth/reset-password",
						method: "POST",
						body: newData,
					};
				},
			}),
		};
	},
});

export const {
	useAuthRegisterMutation,
	useAuthLoginMutation,
	useAuthForgetMutation,
	useAuthNewPasswordMutation,
	useUsermeInfoQuery,
	useUsereditInfoMutation,
	useUsereditProfileMutation,
	useUserresetPasswordMutation,
} = AuthServices;
export default AuthServices;
