import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { arrow } from "../../constant/Index";
import Sidebar from "./Sidebar";
import {
	useDefaultAddressMutation,
	useGetAddresslistQuery,
} from "../../store/services/AddAddressServices";
import Spinner from "../../utils/Loader";

function Myaddress() {
	const { data, isLoading } = useGetAddresslistQuery();
	const [checkDefault, setDefault] = useState();
	const [defaultAddress, response] = useDefaultAddressMutation();
	const myAddressData = data?.response?.data;

	const HandleDefault = () => {
		const formData = new FormData();
		formData.append("address_id", checkDefault);
		defaultAddress(formData);
	};

	useEffect(() => {
		if (checkDefault) {
			HandleDefault();
		}
	}, [checkDefault]);

	return !isLoading ? (
		<>
			{!isLoading ? (
				<>
					<Header />
					{/* My address section */}
					<section className="my-address-sec checkout my-profile-sec">
						<div className="container">
							<div className="row">
								<div className="col-md-3">
									<Sidebar />
								</div>
								<div className="col-md-9 profile-all">
									<div className="row for-some-space">
										<div className="col-md-12">
											<div className="my-profile-head-and-btn">
												<h3>My Addresses</h3>
												<div className="banner-button login-btn profile-edit-btn">
													<Link to="/add-address" className="all-button">
														ADD NEW <img className="img-fluid" src={arrow} />{" "}
													</Link>
												</div>
											</div>
										</div>
									</div>
									<div className="main-address-div">
										{myAddressData?.length > 0 ? (
											myAddressData?.map((item, index) => {
												return (
													<div className="my-all-address" key={index}>
														<div className="saved-address">
															{/* <!-- Saved Address --> */}
															<label className="address-container">
																<input
																	type="radio"
																	// defaultChecked
																	name="addressRadio"
																	className="form-check-input"
																	value={checkDefault}
																	onChange={() => setDefault(item.id)}
																/>
																<span className="checkmark">
																	<div className="select-address">
																		<div className="selection">
																			<i
																				className="fa fa-check"
																				aria-hidden="true"
																			></i>
																		</div>
																		<div className="radio-btn">
																			<span></span>
																		</div>
																		<div className="address-detail">
																			<div className="top">
																				<h5>
																					<span className="property">
																						Country:
																					</span>
																					<span className="value">
																						{item?.country}
																					</span>
																				</h5>
																				<h5>
																					<span className="property pl-4">
																						State:
																					</span>
																					<span className="value">
																						{item?.state}
																					</span>
																				</h5>
																			</div>
																			<h5>
																				<span className="property">
																					Address:
																				</span>
																				<span className="value">
																					{item?.address}
																				</span>
																			</h5>
																			<h5 className="last">
																				<span className="property">
																					Zip Code:
																				</span>
																				<span className="value">
																					{item?.zip}
																				</span>
																			</h5>

																			<div className="button-group address-edit-btn">
																				<Link to={`/edit-address/${item?.id}`}>
																					Edit Details
																				</Link>
																			</div>
																		</div>
																	</div>
																</span>
															</label>
															{/* <!-- Saved Address --> */}
														</div>
													</div>
												);
											})
										) : (
											<h3 className="orderCard">No Address Found</h3>
										)}
									</div>
								</div>
							</div>
						</div>
						<Threelinks />
					</section>
					{/* ========================================= */}

					<Footer />
				</>
			) : (
				<>
					<Spinner />
				</>
			)}
		</>
	) : (
		<Spinner />
	);
}

export default Myaddress;

{
	/* <div className="my-all-address address-second">
								<div className="saved-address">
									<label className="container">
										<input type="radio" defaultChecked name="radio" />
										<span className="checkmark">
											<div className="select-address">
												<div className="selection">
													<i className="fa fa-check" aria-hidden="true"></i>
												</div>
												<div className="radio-btn">
													<span></span>
												</div>
												<div className="address-detail">
													<div className="top">
														<h5>
															<span className="property">Country:</span>
															<span className="value">United States</span>
														</h5>
														<h5>
															<span className="property pl-4">State:</span>
															<span className="value">Texas</span>
														</h5>
													</div>
													<h5>
														<span className="property">Address:</span>
														<span className="value">
															10515 Fox Ave Fairdale, Kentucky(KY), 40118
														</span>
													</h5>
													<h5 className="last">
														<span className="property">Phone:</span>
														<span className="value">+44-123-456-789</span>
													</h5>
													<div className="button-group address-edit-btn">
														<Link to="/add-address">Edit Details</Link>
													</div>
												</div>
											</div>
										</span>
									</label>
								</div>
							</div> */
}
