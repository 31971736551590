import React from "react";
import Fullpage, {
	FullPageSections,
	FullpageSection,
} from "@ap.cx/react-fullpage";

const FullPage = () => {
	return (
		<Fullpage>
			<FullPageSections>
				<FullpageSection
					style={{
						height: "100vh",
						padding: "1em",
					}}
				>
					<h1>1</h1>
				</FullpageSection>
				<FullpageSection
					style={{
						height: "100vh",
						padding: "1em",
					}}
				>
					<h1>2</h1>
				</FullpageSection>
				<FullpageSection
					style={{
						height: "100vh",
						padding: "1em",
					}}
				>
					3
				</FullpageSection>
			</FullPageSections>
		</Fullpage>
	);
};

export default FullPage;
