import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "../components/Scrolltotop";
import About from "../pages/About/About";
import Checkout from "../pages/Checkout/Checkout";
import Membercheckout from "../pages/Checkout/Membercheckout";
import Contact from "../pages/ContactUs/Contact";
import Events from "../pages/Events/Events";
import Eventsdetail from "../pages/Events/Eventsdetail";
import Faq from "../pages/Faq/Faq";
import Home from "../pages/Home/Home";
import Location from "../pages/Location/Location";
import Forgetpassword from "../pages/Login/Forgetpassword";
import Login from "../pages/Login/Login";
import Newpassword from "../pages/Login/Newpassword";
import Register from "../pages/Login/Register";
import Loun from "../pages/Lounge/Loun";
import Memebership from "../pages/Membership/Memebership";
import Addnewaddress from "../pages/Profile/Addnewaddress";
import Changepassword from "../pages/Profile/Changepassword";
import Editprofile from "../pages/Profile/Editprofile";
import Loginmembership from "../pages/Profile/Loginmembership";
import Myaddress from "../pages/Profile/Myaddress";
import Myorders from "../pages/Profile/Myorders";
import Myorderviewdetail from "../pages/Profile/Myorderviewdetail";
import Profile from "../pages/Profile/Profile";
import Cart from "../pages/Shop/Cart";
import Shop from "../pages/Shop/Shop";
import Shopdetail from "../pages/Shop/Shopdetail";
import Privacypolicy from "../pages/TermsCondition/Privacypolicy";
import Terms from "../pages/TermsCondition/Terms";
import Thankyou from "../pages/Thankyou/Thankyou";
import EditAddress from "../pages/Profile/EditAddress";
import Private from "./Private";
import Public from "./Public";
import FullPage from "../components/FullPage";
import NotFound from "../pages/NotFound/NotFound";

function Publicroutes() {
	return (
		<>
			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/shop" element={<Shop />} />
					<Route path="/shop?slug" element={<Shop />} />
					<Route path="/shop-detail/:slug" element={<Shopdetail />} />
					<Route path="/events" element={<Events />} />
					<Route path="/events-detail/:slug" element={<Eventsdetail />} />
					<Route path="/membership" element={<Memebership />} />
					<Route path="/locations" element={<Location />} />
					<Route path="/contact-us" element={<Contact />} />
					<Route path="/lounge-rental" element={<Loun />} />
					<Route path="/faq" element={<Faq />} />
					<Route path="/about-us" element={<About />} />
					<Route path="/terms-condition" element={<Terms />} />
					<Route path="/privacy-policy" element={<Privacypolicy />} />
					<Route path="/full-page" element={<FullPage />} />

					{/* Profile Routes */}

					<Route element={<Public />}>
						<Route path="/login" element={<Login />} />
						<Route path="/register" element={<Register />} />
						<Route path="/forget-password" element={<Forgetpassword />} />
						<Route path="/newpassword/:token" element={<Newpassword />} />
						<Route path="/new-password" element={<Newpassword />} />
					</Route>
					{/* <Route
						path="/new-password"
						element={
							<Public>
								<Newpassword />
							</Public>
						}
					/> */}
					<Route element={<Private />}>
						<Route path="/member-checkout" element={<Membercheckout />} />
						<Route path="/check-out" element={<Checkout />} />
						<Route path="/thank-you" element={<Thankyou />} />
						<Route path="/profile" element={<Profile />} />
						<Route path="/edit-profile" element={<Editprofile />} />
						<Route path="/change-password" element={<Changepassword />} />
						<Route path="/my-orders" element={<Myorders />} />
						<Route
							path="/order-view-detail/:id"
							element={<Myorderviewdetail />}
						/>
						<Route path="/my-address" element={<Myaddress />} />
						<Route path="/add-address" element={<Addnewaddress />} />
						<Route path="/edit-address/:id" element={<EditAddress />} />
						<Route path="/member-ship" element={<Loginmembership />} />
					</Route>
					<Route path="/cart" element={<Cart />} />
					<Route path="*" element={<NotFound />} />

					{/* ===================================== */}
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default Publicroutes;
