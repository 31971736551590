import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { NewsletterEndpoint, Url } from "../../Type/BaseUrl";

const NewsletterService = createApi({
	reducerPath: "NewsletterService",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
	}),
	endpoints: (builder) => {
		return {
			newsletterContent: builder.mutation({
				query: (NewsData) => {
					return {
						url: NewsletterEndpoint,
						method: "Post",
						body: NewsData,
					};
				},
			}),
		};
	},
});

export const { useNewsletterContentMutation } = NewsletterService;
export default NewsletterService;
