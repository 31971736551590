import React, { useState } from "react";
import Slider from "react-slick";
import {
	arrow2,
	blackflower,
	shop1,
	shop2,
	shop3,
	shop4,
	shop5,
	shop6,
	pro1,
	ImgNotFound,
} from "../constant/Index";

const Slide = (props) => {
	const proImage = props.proImage;
	const [nav1, setNav1] = useState();
	const [nav2, setNav2] = useState();
	return (
		<div>
			<Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
				{proImage?.imageurl?.map((data, index) => {
					return (
						<div className="all-borders" key={index}>
							<img className="img-fluid" src={data ? data : ImgNotFound} />
						</div>
					);
				})}
			</Slider>

			<Slider
				asNavFor={nav1}
				ref={(slider2) => setNav2(slider2)}
				slidesToShow={4}
				infinite={false}
				swipeToSlide={true}
				focusOnSelect={true}
			>
				{proImage?.imageurl?.map((data, index) => {
					return (
						<div className="" key={index}>
							<img
								className="img-fluid on-small-border"
								src={data ? data : ImgNotFound}
							/>
						</div>
					);
				})}
			</Slider>
		</div>
	);
};

export default Slide;
