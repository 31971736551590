import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Url } from "../../Type/BaseUrl";

const Products = createApi({
	reducerPath: "product-service",
	tagTypes: "proDetails",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			productContent: builder.query({
				query: (Data) => {
					return {
						url: `/products/all?limit=${9}&page=${
							Data?.page ? Data?.page : 1
						}&category_id=${
							Data?.category_id ? Data?.category_id : ""
						}&search=${Data?.search ? Data?.search : ""}&featured=${
							Data?.featured ? Data?.featured : 0
						}`,
						method: "Get",
					};
				},
			}),
			getAllCategorys: builder.query({
				query: () => {
					return {
						url: `/categories/all`,
						method: "GET",
					};
				},
			}),
			productDetail: builder.query({
				query: (slug) => {
					return {
						url: `/products/details/${slug}`,
						method: "GET",
					};
				},
				providesTags: ["proDetails"],
			}),
			productReviews: builder.mutation({
				query: (data) => {
					return {
						url: "/products/reviews/submit",
						method: "POST",
						body: data,
					};
				},
				invalidatesTags: ["proDetails"],
			}),
		};
	},
});

export const {
	useProductContentQuery,
	useGetAllCategorysQuery,
	useProductDetailQuery,
	useProductReviewsMutation,
} = Products;
export default Products;
