import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import PageHeader from "../../components/Pageheader";
import { arrow, location1, location2, map } from "../../constant/Index";
import Footer from "../../components/Footer";
import Threelinks from "../../components/Threelinks";
import { useLocationContentQuery } from "../../store/services/LocationService";
import { useImageContentQuery } from "../../store/services/ImagesUrlService";
import "../../assets/css/location.css";
import Spinner from "../../utils/Loader";
import { useSettingContentQuery } from "../../store/services/SiteSettingService";

function Location() {
	// Config Urls Api Call
	const { data: siteData, isLoading: siteDataLoad } = useSettingContentQuery();
	const { data: urls, isLoading: urlLoading } = useImageContentQuery();
	let location_image = urls?.response?.data?.location_image_url;
	const eventData = siteData?.response?.data?.[0]?.location_content;
	// Config Urls Api Call

	// Location Api Call
	const { data, isLoading } = useLocationContentQuery();
	let locationData = data?.response?.data;
	// Location Api Call
	return (
		<>
			{!isLoading ? (
				<>
					<Header />

					<PageHeader
						pagename="LOCATIONS"
						boldtext="Book Your Stay at orderzaza's Tranquil Locations"
						pagepara={eventData}
					/>

					{/* Location Section */}
					<section className="location-sec">
						<div className="container">
							<div className="row justify-content-center">
								{locationData?.map((data) => {
									return (
										<div className="col-md-6">
											<div className="location-img">
												<img
													className="img-fluid"
													src={location_image + "/" + data?.image}
												/>

												<div className="location-text">
													<div className="location-head">
														<h3>
															{data?.title}
															<span>
																<img className="img-fluid" src={map} />
															</span>
														</h3>
														<p>{data?.description}</p>
													</div>
													<label>FOR DIRECTIONS</label>
													<div className="banner-button for-direct">
														<a
															target="_blank"
															href={`https://www.google.com/maps/search/?api=1&query=${data?.latitude},${data?.longitude}`}
															className="all-button for-f-btn"
														>
															CLICK HERE
															<img className="img-fluid" src={arrow} />{" "}
														</a>
													</div>
												</div>
											</div>
										</div>
									);
								})}
								{/* <div className="col-md-6">
				<div className="location-img">
					<img className="img-fluid" src={location2} />

					<div className="location-text">
						<div className="location-head">
							<h3>
								LODGE LOUNGE{" "}
								<span>
									<img className="img-fluid" src={map} />
								</span>
							</h3>
							<p>
								Sed velit dignissim sodales ut eu sem. Phasellus egestas
								tellus rutrum tellus pe lle ntesque eu tincidunt tortor
								aliquam. Elit eget{" "}
							</p>
						</div>
						<label>FOR DIRECTIONS</label>
						<div className="banner-button for-direct">
							<Link className="all-button for-f-btn">
								CLICK HERE
								<img className="img-fluid" src={arrow} />{" "}
							</Link>
						</div>
					</div>
				</div>
			</div> */}
							</div>
						</div>
						<Threelinks />
					</section>
					<Footer />
				</>
			) : (
				<>
					<Spinner />
				</>
			)}
		</>
	);
}

export default Location;
