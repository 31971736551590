import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { myorder } from "../../constant/Index";
import Sidebar from "./Sidebar";
import { useGetOderApisQuery } from "../../store/services/PlaceOrderService";
import moment from "moment";
import currency from "currency-formatter";
import Spinner from "../../utils/Loader";
import ReactPaginate from "react-paginate";

function Myorders() {
	const [currentPage, setCurrentPage] = useState(1);
	const navigate = useNavigate();
	let Data = {
		page: currentPage,
	};
	const { data, isLoading } = useGetOderApisQuery(Data);
	const MyOrder = data?.response?.data?.products;
	const pages = Math.ceil(
		data?.response?.data?.total_size / data?.response?.data?.limit,
	);
	const handlePageClick = async (data) => {
		setCurrentPage(data?.selected + 1);
	};

	return !isLoading ? (
		<>
			<Header />
			{/* Myorders Section */}

			<section className="my-orders-sec my-profile-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>
						<div className="col-md-9 profile-all">
							<div className="row">
								<div className="col-md-12">
									<div className="my-profile-head-and-btn my-order-heading">
										<h3>My ORDERS</h3>
									</div>
								</div>
							</div>
							{MyOrder?.length > 0 ? (
								<div className="table-all" style={{ overflow: "auto" }}>
									<table>
										<thead>
											<th>OrderNo</th>
											<th>Order Date</th>
											<th>Status</th>
											<th>Total</th>
											<th></th>
										</thead>

										<tbody>
											{MyOrder?.map((item, index) => {
												return (
													<tr>
														{/* <td className="for-left-border">
															<img className="img-fluid" src={myorder} />
														</td> */}
														<td>
															<p>#{item?.id}</p>
														</td>
														<td>
															<p>{moment(item?.created_at).format("L")}</p>
														</td>
														<td>
															<p style={{ textTransform: "capitalize" }}>
																{item?.order_status}
															</p>
														</td>
														<td>
															<p>
																{currency.format(item?.order_amount, {
																	code: "USD",
																})}
															</p>
														</td>
														<td className="for-right-border">
															<button
																onClick={() =>
																	navigate(`/order-view-detail/${item?.id}`, {
																		state: item,
																	})
																}
															>
																view Details
															</button>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
									<div className="row">
										<div className="col-lg-12 ">
											<div className="pagination-wrapper pagination_btn">
												<ReactPaginate
													previousLabel="<"
													nextLabel=">"
													breakLabel={"..."}
													pageCount={pages}
													marginPagesDisplayed={2}
													pageRangeDisplayed={3}
													onPageChange={handlePageClick}
													containerClassName={"pagination justify-content-end"}
													pageClassName={"page-item"}
													pageLinkClassName={"page-link"}
													previousClassName={"page-item"}
													previousLinkClassName={"page-link"}
													nextClassName={"page-item"}
													nextLinkClassName={"page-link"}
													breakClassName={"page-item"}
													breakLinkClassName={"page-link"}
													activeClassName={"active"}
												/>
											</div>
										</div>
									</div>
								</div>
							) : (
								<h3 className="orderCard">No Order Found</h3>
							)}
						</div>
					</div>
				</div>
				<Threelinks />
			</section>
			<Footer />
		</>
	) : (
		<Spinner />
	);
}

export default Myorders;
