import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { man, myorderview, dummyImage, ImgLoading } from "../../constant/Index";
import { ChangeEvent, useRef } from "react";
import { toast } from "react-toastify";
import {
	useUsereditProfileMutation,
	useUsermeInfoQuery,
} from "../../store/services/AuthServices";
import { logout } from "../../store/reducers/AuthReducer";
import { useDispatch } from "react-redux";
import Spinner from "../../utils/Loader";
function Sidebar() {
	const inputRef = useRef(null);
	const { data, isLoading } = useUsermeInfoQuery();
	const [editData, response] = useUsereditProfileMutation();
	const ProfileData = data?.response?.data;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [newFile, setNewFile] = useState("");
	const [fileupload, setFileupload] = useState(ProfileData?.image);
	const [uploadLoading, setUploadLoading] = useState(false);

	const handleImageUpload = (e) => {
		e.preventDefault();
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onloadend = () => {
			setNewFile(file);
			setFileupload(reader.result);
		};
		setUploadLoading(true);
		const formData = new FormData();
		formData.append("profile_pic", file);
		// formData.append("email", ProfileData?.email);
		// formData.append("f_name", ProfileData?.f_name);
		// formData.append("l_name", ProfileData?.l_name);
		// formData.append("phone", ProfileData?.phone);
		// formData.append("street_address", ProfileData?.street_address);
		editData(formData);
	};

	useEffect(() => {
		if (response?.isError) {
			toast.error(response?.error?.data?.errors?.[0]);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response?.isSuccess) {
			toast.success(response?.data?.message);
		}
	}, [response?.data?.message]);

	const HandleLogout = (e) => {
		e.preventDefault();
		dispatch(logout());
		navigate("/");
	};

	return (
		<>
			{response?.isLoading ? (
				<Spinner />
			) : (
				<div className="siderbar-tabs">
					<div className="sidebar-img">
						{!ProfileData?.profile_pic ? (
							<>
								<input
									type="file"
									id="upload-photo"
									name=""
									value=""
									multiple="false"
									accept="image/*"
									onChange={handleImageUpload}
									style={{ display: "none" }}
								/>

								<label for="upload-photo" className="side-abr-icon">
									<i className="fa fa-camera" aria-hidden="true"></i>
								</label>
								<img
									className="img-fluid"
									style={{ background: "#f9f9f9" }}
									src={dummyImage}
								/>
							</>
						) : (
							<>
								<input
									type="file"
									id="upload-photo"
									name=""
									value=""
									multiple="false"
									accept="image/*"
									onChange={handleImageUpload}
									style={{ display: "none" }}
								/>

								<label for="upload-photo" className="side-abr-icon">
									<img
										className="img-fluid"
										src={ProfileData?.profile_pic_url}
									/>
									<i className="fa fa-camera" aria-hidden="true"></i>
								</label>
							</>
						)}

						{/* 👇 Notice the `display: hidden` on the input */}

						{/* {file ? `${file.name}` : ""} */}
					</div>
					<ul className="sidebar-ul">
						<li>
							<Link to="/profile">My Profile</Link>
						</li>
						<li>
							<Link to="/my-address">My Addresses</Link>
						</li>
						<li>
							<Link to="/my-orders">My Orders</Link>
						</li>
						<li>
							<Link to="/member-ship">My Membership</Link>
						</li>
						<li>
							<button onClick={HandleLogout}>Logout</button>
						</li>
					</ul>
				</div>
			)}
			{/* Side bar section */}
		</>
	);
}

export default Sidebar;
