import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
	Url,
	MembershipEndpoint,
	MembershipPlaceOrderEndpoint,
	GetMembershipEndpoint,
	CancelMembershipEndpoint,
} from "../../Type/BaseUrl";

const MembershipService = createApi({
	reducerPath: "membership-all",
	tagTypes: "memberShip",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			headers.set("Access-Control-Allow-Origin", "*");

			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			membershipContent: builder.query({
				query: () => {
					return {
						url: MembershipEndpoint,
						method: "Get",
					};
				},
			}),
			membershipPlaceorder: builder.mutation({
				query: (memberData) => {
					return {
						url: MembershipPlaceOrderEndpoint,
						method: "POST",
						body: memberData,
					};
				},
			}),
			getmembershipData: builder.query({
				query: () => {
					return {
						url: GetMembershipEndpoint,
						method: "GET",
					};
				},
				providesTags: ["memberShip"],
			}),
			cancelmembership: builder.mutation({
				query: () => {
					return {
						url: CancelMembershipEndpoint,
						method: "POST",
					};
				},
				invalidatesTags: ["memberShip"],
			}),
		};
	},
});

export const {
	useMembershipContentQuery,
	useMembershipPlaceorderMutation,
	useCancelmembershipMutation,
	useGetmembershipDataQuery,
} = MembershipService;
export default MembershipService;
