import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { capture } from "../../constant/Index";
import { useDispatch } from "react-redux";
import { useAuthForgetMutation } from "../../store/services/AuthServices";
import { toast } from "react-toastify";

function Forgetpassword() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState();
	const [forgetData, response] = useAuthForgetMutation();

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!email) {
			return toast.error("Please enter Your Email");
		}
		const formData = new FormData();
		formData.append("identity", email);
		forgetData(formData);
	};

	useEffect(() => {
		if (response?.isSuccess) {
			setEmail("");
			toast(response?.data?.message);
		}
	}, [response.isSuccess]);

	useEffect(() => {
		if (response?.isError) {
			if (response?.isError && response?.error?.data?.errors) {
				for (let key in response?.error?.data?.errors) {
					if (response?.error?.data?.errors.hasOwnProperty(key)) {
						toast.error(response?.error?.data?.errors[key][0]);
					}
				}
			}
			// toast.error(response?.error?.data?.errors?.identity?.[0]);
		}
	}, [response?.isError]);

	return (
		<>
			{/* Forgot Password */}
			<section className="login-sec">
				<div className="row">
					<div className="col-md-7">
						<div className="login-form-all">
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="login-head forget-password-head">
										<h2>Forget Password</h2>
										<p>A password reset email will be sent</p>
										<p className="login-para">
											Haven't Recieved the link ?{" "}
											<Link to="/register">Try Again</Link>
										</p>
									</div>
									<div className="col-md-12">
										<div className="form-dv-input">
											<input
												type="text"
												placeholder="Email"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</div>
									</div>

									{/* <div className="col-md-12">
                    <div className="remeber-and-forgot new">
                      <div className="form-group">
                        <input type="checkbox" id="html" />
                        <label htmlFor="html">Remeber me</label>
                      </div>

                      <div className="form-group">
                        <Link>Forgot Password ?</Link>
                      </div>
                    </div>
                  </div> */}

									<div className="col-md-12">
										<div className="banner-button submit-banner-btn login-btn">
											<button type="submit" className="all-button submitButton">
												{response?.isLoading ? "Loading..." : "SUBMIT"}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>

					<div className="col-md-5">
						<div className="login-form-img">
							<img className="img-fluid" src={capture} />
							<div className="login-form-text">
								<div className="about-banner-text login-all-text">
									<h5>SMOKE PIPE EVERYDAY</h5>
									<h2>orderzaza Smoke Shop.</h2>
									<p>
										At orderzaza, we believe that cannabis can be a valuable
										part of a healthy and enjoyable lifestyle. That’s why we
										offer a wide range of top-quality cannabis products and
										accessories to suit all types of consumers. Whether you’re a
										seasoned pro or just starting out, we’ve got something for
										everyone. Our team is dedicated to providing excellent
										customer service and helping you find the perfect products
										to meet your needs. In addition to our retail offerings, we
										also have a 420 smoke lounge where you can relax and enjoy
										your purchases in a comfortable and welcoming atmosphere.
										We’re here to make your cannabis experience the best it can
										be. Thank you for choosing orderzaza.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ==================================== */}
		</>
	);
}

export default Forgetpassword;
