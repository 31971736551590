import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageHeader from "../../components/Pageheader";
import Threelinks from "../../components/Threelinks";
import { arrow, blackflower, dollar } from "../../constant/Index";
import Membership from "../../components/Membership";
import { useMembershipContentQuery } from "../../store/services/Membership";
import Spinner from "../../utils/Loader";

function Memebership() {
	const { data, isLoading } = useMembershipContentQuery();
	return (
		<>
			{!isLoading ? (
				<>
					<Header />
					<PageHeader
						pagename="Membership"
						pagepara="After you’ve had your complete time with our limited-time free membership, you can consider our modest membership plans."
					/>
					{/* Become Member section  */}
					<section className="become-member-sec  member-ship-sec">
						<div className="container">
							<div className="row">
								{/* <div className="col-md-4">
              <div className="all-become-member-div">
                <div className="first-dv">
                  <div className="member-text">
                    <h5>Basic</h5>
                    <h2>
                      <span className="dollar"><img className="img-fluid" src={dollar} /></span>350<span> /mo</span>
                    </h2>
                  </div>
                </div>
                <div className="become-member-ul for-responsive-member">
                  <ul className="member-ul">
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Member Card
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Earn Bonus
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Lounge Access
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Free Sample
                    </li>
                    <li>
                      {" "}
                      <span>
                        <img className="img-fluid" src={blackflower} />
                      </span>{" "}
                      Special Offers
                    </li>
                  </ul>

                  <div className="banner-button member-btn">
                    <Link to="/member-checkout" className="all-button">
                      BUY NOW <img className="img-fluid" src={arrow} />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}

								<Membership />
							</div>
						</div>
						<Threelinks />
					</section>
					{/* ========================================================== */}

					<Footer />
				</>
			) : (
				<>
					<Spinner />
				</>
			)}
		</>
	);
}

export default Memebership;
