import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const PageHeader = (props) => {
	useEffect(() => {
		let currentUrl = window.location.href.split("/");
	}, []);
	return (
		<>
			{/* page header starts here */}
			<section className="pageheader">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="content-wrapper">
								<h5>
									SMOKE <span className="for-gap-in-words">PIPE </span>EVERYDAY
								</h5>
								<h2>
									{props.pagename}
									<span>.</span>
								</h2>
								{/* <p>
									<b className="d-block">{props.boldtext}</b>
									{props.pagepara}
								</p> */}
								<p
									className={
										props.pageUrl !== "event"
											? "d-block text-sigle"
											: "text-double d-block paraghraf_set"
									}
									dangerouslySetInnerHTML={{ __html: props.pagepara }}
								></p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* page header ends here */}
		</>
	);
};

export default PageHeader;
