import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { arrow2 } from "../constant/Index";
import { useProductContentQuery } from "../store/services/ProductService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import currency from "currency-formatter";

const FeatureProduct = () => {
	const [variation, setVariation] = useState();
	let Data = {
		featured: 1,
	};
	const { data: feturedProduct, isLoading: feturedLoading } =
		useProductContentQuery(Data);
	const feturedProductData = feturedProduct?.response?.data?.products;

	const { userToken } = useSelector((item) => item.AuthReducer);
	const navigate = useNavigate();
	const [count, setCount] = useState(1);
	const dispatch = useDispatch();

	const HandleClick = (slug) => {
		navigate(`/shop-detail/${slug}`);
	};


	return (
		<>
			{/* Feature Product Section */}
			<section className="smoke-shop-section featured-products">
				<div className="container">
					<div className="about-banner-text shop-banner-text">
						<h5>
							SMOKE <span className="for-gap-in-words">PIPE</span> EVERYDAY
						</h5>
						<h2>
							FEATURED PRODUCTS<span>.</span>
						</h2>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="row">
								{feturedProductData?.slice(0, 4)?.map((data, index) => {
									return (
										<div className="col-md-3" key={index}>
											<div className="all-shop-product-div">
												<div
													className="shop-img"
													onClick={() => HandleClick(data?.slug)}
												>
													<img
														className="img-fluid"
														src={data?.thumbnail}
													></img>
												</div>
												<h2 title={data?.name} className="line-clamp1">
													{data?.name}
												</h2>
												<p
													dangerouslySetInnerHTML={{ __html: data?.details }}
												></p>

												<h5>
													USD{" "}
													{currency.format(data?.unit_price, {
														code: "USD",
													})}
													
													<span className="small-data">
														THC : ({data?.thc ? data?.thc : "0"}%){" "}
													</span>
												</h5>
												<div className="sevev-dis">
													<div className="variation-input">
														{/* {data?.variant_array?.map((item, index) => {
															return (
																<>
																	<label
																		key={index}
																		className="variation-container"
																		onClick={() => setVariation(item)}
																	>
																		<input
																			className="form-check-input mt-0"
																			type="radio"
																			hidden
																			name="variaInput"
																			aria-label="Radio button for following text input"
																		/>
																		<span className="checkmark">
																			<h4>{`${item?.type}G`}</h4>
																		</span>
																	</label>
																</>
															);
														})} */}
													</div>
												</div>

												<div className="Link-all">
													<button
														onClick={() =>
															navigate(`/shop-detail/${data?.slug}`)
														}
														className="setColor"
													>
														View Detail{" "}
														<img className="img-fluid" src={arrow2} />
													</button>
												</div>

												<div className="t-product">
													<p>{data?.toppro}</p>
												</div>
												<div className="t-sale">
													<p>{data?.topsale}</p>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Feature Product Section */}
		</>
	);
};

export default FeatureProduct;
