import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
	Url,
	SettingEndpoint,
	CouponCodeEndpoint,
	BannerEndpoint,
} from "../../Type/BaseUrl";

const SiteSetting = createApi({
	reducerPath: "sitesetting",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			settingContent: builder.query({
				query: () => {
					return {
						url: SettingEndpoint,
						method: "Get",
					};
				},
			}),
			bannerContent: builder.query({
				query: () => {
					return {
						url: BannerEndpoint,
						method: "Get",
					};
				},
			}),
			couponCodeApply: builder.mutation({
				query: (data) => {
					return {
						url: CouponCodeEndpoint,
						method: "POST",
						body: data,
					};
				},
			}),
		};
	},
});

export const {
	useSettingContentQuery,
	useBannerContentQuery,
	useCouponCodeApplyMutation,
} = SiteSetting;
export default SiteSetting;
