// import { Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";

// const Private = ({ children }) => {
// 	const { userToken } = useSelector((state) => state.AuthReducer);
// 	return userToken ? children : <Navigate to="/login" />;
// };
// export default Private;

import {useSelector} from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
const UserRoute = () => {
	const { userToken } = useSelector((state) => state.AuthReducer);
    return userToken ? <Outlet /> : <Navigate to="/login" />
}
export default UserRoute;