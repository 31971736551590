import React, { useEffect } from "react";
import ReactStars from "react-stars";
import { useProductReviewsMutation } from "../../store/services/ProductService";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { arrow } from "../../constant/Index";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useUsermeInfoQuery } from "../../store/services/AuthServices";
import { Rating } from "react-simple-star-rating";

const PostReview = ({ id }) => {
	const navigate = useNavigate();
	const { userToken } = useSelector((item) => item.AuthReducer);
	const [getData, response] = useProductReviewsMutation();
	const { data, isLoading } = useUsermeInfoQuery();
	let userInfo = data?.response?.data;

	// Form States
	const [stars, setStars] = useState();
	let [rating, setRating] = useState(0);
	// const [fname, setFname] = useState();
	// const [userEmail, setUserEmail] = useState();
	// const [review, setReview] = useState();

	const [state, setState] = useState({
		fname: "",
		userEmail: "",
		review: "",
	});

	useEffect(() => {
		setState({
			fname: userInfo?.f_name,
			userEmail: userInfo?.email,
		});
	}, [userInfo]);

	// Form States

	const HandleChanger = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
	};

	const handleClick = (e) => {
		e.preventDefault();
		if (!userToken) {
			toast.error("Please login");
			navigate("/login");
			return;
		}

		if (state?.review === "") {
			toast.error("Please add review");
			return;
		}

		if (!rating) {
			toast.error("Please Select Rating");
			return;
		}

		const fromData = new FormData();
		fromData.append("product_id", id);
		fromData.append("comment", state?.review);
		fromData.append("rating", rating);
		fromData.append("name", userInfo?.f_name);
		fromData.append("email", userInfo?.email);
		getData(fromData);
	};

	useEffect(() => {
		if (response?.isError) {
			if (response?.isError && response?.error?.data?.errors) {
				for (let key in response?.error?.data?.errors) {
					if (response?.error?.data?.errors.hasOwnProperty(key)) {
						toast.error(response?.error?.data?.errors[key]);
					}
				}
			}
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response?.isSuccess) {
			setState({
				review: "",
			});
			setRating(0);
			toast.success("Review submitted successfully");
		}
	}, [response?.isSuccess]);

	const ratingChanged = (newRating) => {
		setStars(newRating);
	};

	const handleRating = (rate) => {
		setRating(rate);
	};

	return (
		<>
			<form>
				<div className="row">
					<div className="col-md-4">
						<div className="input-reviews">
							<label>Name</label>

							<input
								type="text"
								name="fname"
								value={userInfo?.f_name}
								disabled
								onChange={HandleChanger}
								placeholder="Write your name here.."
							/>
						</div>
					</div>

					<div className="col-md-4">
						<div className="input-reviews">
							<label>Email Address</label>
							<input
								type="text"
								name="userEmail"
								value={userInfo?.email}
								disabled
								onChange={HandleChanger}
								placeholder="Write your email here..."
							/>
						</div>
					</div>
					<div className="col-md-4">
						<div className="input-reviews set_rating">
							<label>Rating</label>
							{/* <input
								type="tel"
								value={phone}
								onChange={(e) => setStars(e.target.value)}
								placeholder="Write your phone here..."
							/> */}
							{/* <ReactStars
								count={5}
								onChange={ratingChanged}
								size={24}
								color2={"#ffd700"}
							/> */}
							<Rating
								onClick={handleRating}
								initialValue={rating}
								size={24}
								color2={"#ffd700"}
							/>
						</div>
					</div>

					<div className="col-md-12">
						<div className="form-text-area">
							<label>Your Review</label>

							<textarea
								className="form-control"
								name="review"
								maxlength="150"
								value={state?.review}
								onChange={HandleChanger}
								id="exampleFormControlTextarea1"
								rows="3"
								placeholder="Write your message here..."
							></textarea>
						</div>
					</div>

					<div className="col-md-6 hide-on-responsive"></div>
					<div className="col-md-6">
						<div className="banner-button home-about-banner-btn">
							<button
								onClick={handleClick}
								type="button"
								className="all-button submitButton"
							>
								{response?.isLoading ? "Loading..." : "S U B M I T"}{" "}
								<img className="img-fluid" src={arrow} />{" "}
							</button>
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

export default PostReview;
