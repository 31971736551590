import React from "react";
import { Link } from "react-router-dom";
import { footerlogo } from "../constant/Index";
import { useSettingContentQuery } from "../store/services/SiteSettingService";
import {
	useCoreimageContentQuery,
	useImageContentQuery,
} from "../store/services/ImagesUrlService";
import { useSocialContentQuery } from "../store/services/SocialService";

function Footer() {
	// Config Url Api Call
	const { data: logoData, isLoading: logoLoading } = useImageContentQuery();
	let Imageurl = logoData?.response?.data?.company_logo_url;

	// Config Url Api Call

	// Info Api Call Api Call
	const { data: infoData, isLoading: infoLoading } = useCoreimageContentQuery();
	let footer_info = infoData?.response?.data;
	// Info Api Call Api

	// SocialMedia Api Call
	const { data: socialLinks, isLoading: socialLoading } =
		useSocialContentQuery();
	let socialData = socialLinks?.response?.data;
	// SocialMedia Api Call

	return (
		<>
			{/* Footer section */}
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="footer-web-logo">
								<Link to="/">
									{footer_info?.length > 0 ? (
										<img
											className="img-fluid"
											src={
												Imageurl + "/" + footer_info && footer_info[5]
													? Imageurl + "/" + footer_info[5]?.value
													: null
											}
										/>
									) : null}
								</Link>
							</div>
						</div>

						<div className="col-lg-2">
							<div className="footer-anchor">
								{footer_info?.length > 0 ? (
									<a href={"mailto:" + footer_info[4]?.value}>
										{footer_info.length > 0 ? footer_info[4]?.value : null}
									</a>
								) : null}
								<br />
								{footer_info?.length > 0 ? (
									<a href={"tel:" + footer_info[0]?.value}>
										{" "}
										{footer_info[0]?.value}
									</a>
								) : null}
							</div>
						</div>

						<div className="col-lg-2">
							<div className="footer-address">
								{footer_info?.length > 0 ? (
									<p>{footer_info[8]?.value}</p>
								) : null}
							</div>
						</div>

						<div className="col-lg-2">
							<div className="footer-links">
								<ul>
									{socialData?.map((data, index) => {
										return (
											<li key={index + 1}>
												<a target="_blank" href={data?.link}>
													{data?.name}
												</a>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
						{/* <div className="col-md-1">
							<div className="footer-links">
								<a href="">Vimeo</a>
								<a href="">Instagram</a>
							</div>
						</div>
						<div className="col-md-1">
							<div className="footer-links">
								<a href="">Google+</a>
								<a href="">Youtube</a>
							</div>
						</div> */}
					</div>
				</div>
				<div className="container-fluid copy__wrapp">
					<div className="row">
						<div className="col-lg-8">
							<div className="copy-right">
								{footer_info?.length > 0 ? (
									<p>{footer_info[6]?.value}</p>
								) : null}
							</div>
						</div>
						<div className="col-lg-4">
							<div className="terms-about-policy">
								<Link to="/about-us">About Us</Link>
								<Link to="/terms-condition" className="brder-left-right">
									Terms and Conditions
								</Link>
								<Link to="/privacy-policy">Privacy Policy</Link>
							</div>
						</div>
					</div>
				</div>
			</footer>

			{/* =========== */}
		</>
	);
}

export default Footer;
