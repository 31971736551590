import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Url, SocialEndpoint } from "../../Type/BaseUrl";

const SocialService = createApi({
	reducerPath: "social-media",
	baseQuery: fetchBaseQuery({ baseUrl: Url }),
	endpoints: (builder) => {
		return {
			socialContent: builder.query({
				query: () => {
					return {
						url: SocialEndpoint,
						method: "Get",
					};
				},
			}),
		};
	},
});

export const { useSocialContentQuery } = SocialService;
export default SocialService;
