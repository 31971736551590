import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageHeader from "../../components/Pageheader";
import Threelinks from "../../components/Threelinks";
import { useSettingContentQuery } from "../../store/services/SiteSettingService";
import Spinner from "../../utils/Loader";

function Privacypolicy() {
	const { data, isLoading } = useSettingContentQuery();
	let settingData = data?.response?.data[0];
	return (
		<>
			{!isLoading ? (
				<>
					<Header />
					<PageHeader pagename="PRIVACY POLICY" />
					{/* Terms and condition */}
					<section className="terms-conditons-sec">
						<div className="container">
							<div
								dangerouslySetInnerHTML={{
									__html: settingData?.privacy_policy,
								}}
							></div>
						</div>
					</section>
					{/* ========================================== */}
					<Footer />
					<Threelinks />
				</>
			) : (
				<>
					<Spinner />
				</>
			)}
		</>
	);
}

export default Privacypolicy;
