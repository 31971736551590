import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { arrow } from "../../constant/Index";
import Sidebar from "./Sidebar";
import {
	useGetSingleAddressQuery,
	useUpdateAddresslistMutation,
} from "../../store/services/AddAddressServices";
import { toast } from "react-toastify";

function EditAddress() {
	const { id } = useParams();
	const [updatedAddress, response] = useUpdateAddresslistMutation();
	const { data, isLoading } = useGetSingleAddressQuery(id);
	const myAddressData = data?.response?.data?.address?.[0];
	const navigate = useNavigate();
	const [state, setState] = useState({
		address: "",
		city: "",
		country: "",
		states: "",
		zip: "",
	});

	const HandleChanger = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		setState({
			address: myAddressData?.address,
			city: myAddressData?.city,
			country: myAddressData?.country,
			states: myAddressData?.state,
			zip: myAddressData?.zip,
		});
	}, [myAddressData]);

	const HandleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("address", state?.address);
		formData.append("city", state?.city);
		formData.append("country", state?.country);
		formData.append("state", state?.states);
		formData.append("zip", state?.zip);
		let datas = {
			data: formData,
			id,
		};
		updatedAddress(datas);
	};

	useEffect(() => {
		if (response.isError && response?.error?.data?.errors) {
			for (let key in response?.error?.data?.errors) {
				if (response?.error?.data?.errors.hasOwnProperty(key)) {
					toast.error(response?.error?.data?.errors[key][0]);
				}
			}
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response?.isSuccess) {
			toast(response?.data?.message);
			navigate("/my-address");
		}
	}, [response.isSuccess]);
	return (
		<>
			<Header />
			{/* Add Address section */}
			<section className="add-new-address my-profile-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>
						<div className="col-md-9 profile-all">
							<div className="add-all-new-address">
								<div className="row">
									<div className="col-md-12">
										<div className="my-profile-head-and-btn">
											<h3>My Addresses</h3>
											<div className="banner-button login-btn profile-edit-btn">
												<button
													onClick={HandleSubmit}
													className="all-button submitButton"
												>
													{response?.isLoading ? "loading..." : "SAVE ADDRESS"}
													<img className="img-fluid" src={arrow} />{" "}
												</button>
											</div>
										</div>
									</div>
								</div>
								<form>
									<div className="row">
										<div className="col-md-12">
											<div className="add-address-input">
												<label>Address </label>
												<input
													type="text"
													placeholder="Enter Address"
													name="address"
													value={state?.address}
													onChange={HandleChanger}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="add-address-input">
												<label>City</label>
												<input
													type="text"
													placeholder="Enter city"
													name="city"
													value={state?.city}
													onChange={HandleChanger}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="add-address-input">
												<label>Country</label>
												<input
													type="text"
													placeholder="Enter country"
													name="country"
													value={state?.country}
													onChange={HandleChanger}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="add-address-input">
												<label>State</label>
												<input
													type="text"
													placeholder="Enter state"
													name="states"
													value={state?.states}
													onChange={HandleChanger}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="add-address-input">
												<label>Zipcode</label>
												<input
													type="text"
													placeholder="Enter zipcode"
													name="zip"
													value={state?.zip}
													onChange={HandleChanger}
												/>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<Threelinks />
			</section>

			<Footer />
		</>
	);
}

export default EditAddress;
