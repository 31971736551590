import React from "react";
import { capture } from "../../constant/Index";
import login from "../../assets/css/login.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuthLoginMutation } from "../../store/services/AuthServices";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserToken } from "../../store/reducers/AuthReducer";
import { toast } from "react-toastify";

function Login() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [loginData, response] = useAuthLoginMutation();

	useEffect(() => {
		getcookiedata();
	}, []);

	const getcookiedata = () => {
		let Eml = getCookies("email");
		let PaS = getCookies("password");
		if (Eml && PaS) {
			setEmail(Eml);
			setPassword(PaS);
		}
	};

	const getCookies = (cemail) => {
		let email = cemail + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === " ") {
				c = c.substring(1);
			}
			if (c.indexOf(email) === 0) {
				return c.substring(email.length, c.length);
			}
		}
		return "";
	};

	const HandleRemember = () => {
		let emi = email;
		let pass = password;
		document.cookie = `email=${emi};path=https://pipe-paraadise.developer-ourbase-camp.com/login`;
		document.cookie = `password=${pass};path=https://pipe-paraadise.developer-ourbase-camp.com/login`;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!email || !password) {
			return toast.error("Please enter all valid fields");
		}
		const formData = new FormData();
		formData.append("email", email);
		formData.append("password", password);
		loginData(formData);
	};

	useEffect(() => {
		if (response?.isSuccess) {
			setEmail("");
			setPassword("");
			dispatch(setUserToken(response?.data?.response?.data));
			localStorage.setItem(
				"userData",
				JSON.stringify(response?.data?.response?.data),
			);
			toast(response?.data?.message);
			navigate("/");
		} else {
			toast(response?.data?.message);
		}
	}, [response.isSuccess]);

	useEffect(() => {
		if (response?.isError) {
			// toast.error(response?.error?.data?.errors[0]?.message);
			if (response?.isError && response?.error?.data?.errors) {
				for (let key in response?.error?.data?.errors) {
					if (response?.error?.data?.errors.hasOwnProperty(key)) {
						toast.error(response?.error?.data?.errors[key][0]);
					}
				}
			}
		}
	}, [response?.isError]);
	return (
		<>
			{/* Login Section */}
			<section className="login-sec">
				<div className="row">
					<div className="col-md-7">
						<div className="login-form-all">
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="login-head">
										<h2>LOGIN</h2>
										<p>
											Welcome Back to <span>orderzaza</span>
											<br />
											The Best place to enhance your look{" "}
										</p>
										<p className="login-para">
											Not a Member ? <Link to="/register">Register</Link>
										</p>
									</div>
									<div className="col-md-12">
										<div className="form-dv-input">
											<input
												type="email"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												placeholder="Email"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-dv-input">
											<input
												type="password"
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												placeholder="Password"
											/>
										</div>
									</div>

									<div className="col-md-12">
										<div className="remeber-and-forgot new">
											<div className="form-group">
												<input
													type="checkbox"
													id="html"
													onClick={HandleRemember}
												/>
												<label htmlFor="html"> remember me</label>
											</div>

											<div className="form-group">
												<Link to="/forget-password">Forgot Password ?</Link>
											</div>
										</div>
									</div>

									<div className="col-md-12">
										<div className="banner-button submit-banner-btn login-btn">
											<button type="submit" className="all-button submitButton">
												{response?.isLoading ? "Loading..." : "LOGIN"}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>

					<div className="col-md-5">
						<div className="login-form-img">
							<img className="img-fluid" src={capture} />
							<div className="login-form-text">
								<Link to="/">
									<div className="about-banner-text login-all-text">
										<h5>SMOKE PIPE EVERYDAY</h5>
										<h2>orderzaza Smoke Shop.</h2>
										<p>
											Welcome to orderzaza, your go-to source for high-quality
											cannabis products and accessories. We offer a wide range
											of options to suit all types of consumers, from the
											occasional user to the seasoned connoisseur. Our friendly
											and knowledgeable staff are dedicated to providing
											excellent customer service and helping you find the
											perfect products to meet your needs. In addition to our
											retail offerings, we also have a 420 smoke lounge where
											you can relax and enjoy your purchases in a comfortable
											and welcoming atmosphere.
										</p>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ===================== */}
		</>
	);
}

export default Login;
