import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Url, PlaceOrderEndpoint, OrderListEndpoint } from "../../Type/BaseUrl";

const PlaceOrderService = createApi({
	reducerPath: "place-service",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			placeOrderApis: builder.mutation({
				query: (orderData) => {
					return {
						url: PlaceOrderEndpoint,
						method: "Post",
						body: orderData,
					};
				},
			}),
			getOderApis: builder.query({
				query: (Data) => {
					return {
						url: `${OrderListEndpoint}?limit=${3}&page=${
							Data?.page ? Data?.page : 1
						}`,
						method: "GET",
					};
				},
			}),
			getSingleOderApis: builder.query({
				query: (id) => {
					return {
						url: `${OrderListEndpoint}/${id}`,
						method: "GET",
					};
				},
			}),
		};
	},
});

export const {
	usePlaceOrderApisMutation,
	useGetOderApisQuery,
	useGetSingleOderApisQuery,
} = PlaceOrderService;
export default PlaceOrderService;
