import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageHeader from "../../components/Pageheader";
import { arrow, blackflower, checkoutimg, dollar } from "../../constant/Index";
import { useSelector } from "react-redux";
import { useImageContentQuery } from "../../store/services/ImagesUrlService";
import currency from "currency-formatter";
import {
	useDefaultAddressMutation,
	useGetAddresslistQuery,
} from "../../store/services/AddAddressServices";
import { useMembershipPlaceorderMutation } from "../../store/services/Membership";
import MembershipPayment from "./MembershipPayment";
import { toast } from "react-toastify";
import { useEffect } from "react";

function Membercheckout() {
	const navigate = useNavigate();

	const [state, setStates] = useState({
		f_Name: "",
		l_Name: "",
		email: "",
		address: "",
		suite: "",
		city: "",
		zip: "",
		phone: "",
		states: "",
		country: "",
	});
	const { memberData, memberhipCardNonce } = useSelector(
		(item) => item.MembershipReducer,
	);
	const [condition, setCondition] = useState(false);
	const { data: logoData, isLoading: logoLoading } = useImageContentQuery();
	let Imageurl = logoData?.response?.data?.membership_image_url;
	const { data: userAddress, isLoading: load } = useGetAddresslistQuery();
	const [checkDefault, setDefault] = useState();
	const [defaultAddress, responsedata] = useDefaultAddressMutation();
	const [placeOrderData, placeOderresponse] = useMembershipPlaceorderMutation();
	const myAddressData = userAddress?.response?.data;

	const PlaceOrderData = (e) => {
		e.preventDefault();
		const formData = new FormData();
		if (checkDefault !== undefined) {
			if (!memberhipCardNonce) {
				return toast.error("Please Enter Your Card Number");
			} else if (condition === false) {
				return toast.error("Please Check Condition");
			} else {
				formData.append("address_id", checkDefault);
				formData.append("payment_method", "square");
				formData.append("membership_id", memberData?.id);
				formData.append("card_nonce", memberhipCardNonce);
				placeOrderData(formData);
			}
		} else {
			if (
				!state?.f_Name ||
				!state?.l_Name ||
				!state?.address ||
				!state?.suite ||
				!state?.zip ||
				!state?.phone ||
				!state?.states ||
				!state?.country
			) {
				return toast.error("Please Enter All fileds");
			} else {
				if (!memberhipCardNonce) {
					return toast.error("Please Enter Your Card Number");
				} else if (condition === false) {
					return toast.error("Please Check Condition");
				} else {
					formData.append("first_name", state?.f_Name);
					formData.append("last_name", state?.l_Name);
					formData.append("address", state?.address);
					formData.append("suite", state?.suite);
					formData.append("city", state?.city);
					formData.append("zip", state?.zip);
					formData.append("phone", state?.phone);
					formData.append("state", state?.states);
					formData.append("country", state?.country);
					formData.append("payment_method", "square");
					formData.append("card_nonce", memberhipCardNonce);
					formData.append("membership_id", memberData?.id);
					formData.append("address_id", "");
					placeOrderData(formData);
				}
			}
		}
	};

	const HandleChanger = (e) => {
		setStates({ ...state, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		if (placeOderresponse?.isError) {
			toast.error(placeOderresponse?.error?.data?.message);
		}
	}, [placeOderresponse?.error?.data]);

	let obj = {
		addressId: checkDefault ? checkDefault : null,
		couponCode: null,
		totalAmount: memberData?.price,
		address: state?.address ? state?.address : "",
		country: state?.country ? state?.country : "",
		phone: state?.phone ? state?.phone : "",
		state: state?.states ? state?.states : "",
		location: "/member-checkout",
	};

	useEffect(() => {
		if (placeOderresponse?.isSuccess) {
			toast.success(placeOderresponse?.data?.message);
			navigate("/thank-you", { state: obj });
		}
	}, [placeOderresponse?.data?.message]);

	if (!memberData?.id) {
		return navigate("/membership");
	}

	return (
		<>
			<Header />
			<PageHeader pagename="Checkout" />

			<section className="check-out-sec member-checkout-sec checkout">
				<div className="container">
					<div className="row">
						<div className="col-md-8 ">
							<div className="all-address-box">
								{myAddressData?.map((item, index) => {
									return (
										<div className="my-all-address" key={index}>
											<div className="saved-address">
												{/* <!-- Saved Address --> */}
												<label className="address-container">
													<input
														type="radio"
														// defaultChecked
														name="addressRadio"
														className="form-check-input"
														value={checkDefault}
														onChange={() => setDefault(item.id)}
													/>
													<span className="checkmark">
														<div className="select-address">
															<div className="selection">
																<i
																	className="fa fa-check"
																	aria-hidden="true"
																></i>
															</div>
															<div className="radio-btn">
																<span></span>
															</div>
															<div className="address-detail">
																<div className="top">
																	<h5>
																		<span className="property">Country:</span>
																		<span className="value">
																			{item?.country}
																		</span>
																	</h5>
																	<h5>
																		<span className="property pl-4">
																			State:
																		</span>
																		<span className="value">{item?.state}</span>
																	</h5>
																</div>
																<h5>
																	<span className="property">Address:</span>
																	<span className="value">{item?.address}</span>
																</h5>
																<h5 className="last">
																	<span className="property">Zip Code:</span>
																	<span className="value">{item?.zip}</span>
																</h5>
																<div className="button-group address-edit-btn">
																	<Link to={`/edit-address/${item?.id}`}>
																		Edit Details
																	</Link>
																</div>
															</div>
														</div>
													</span>
												</label>
												{/* <!-- Saved Address --> */}
											</div>
										</div>
									);
								})}
								<label className="address-container">
									<input
										type="radio"
										defaultChecked
										name="addressRadio"
										className="form-check-input"
										// value={checkDefault}
										hidden
									/>
									<div className="billin-dv">
										<h4>BILLING DETAILS</h4>
									</div>
									<span className="checkmark">
										<div className="check-dot"></div>
										<div className="add-new-address">
											<div className="row">
												<div className="col-md-6">
													<div className="form-dv-input check-out-input">
														<label>First Name</label>
														<input
															type="text"
															name="f_Name"
															value={state?.f_Name}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-dv-input check-out-input">
														<label>Last Name</label>
														<input
															type="text"
															name="l_Name"
															value={state?.l_Name}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-dv-input check-out-input">
														<label>Phone</label>
														<input
															type="number"
															name="phone"
															value={state?.phone}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-dv-input check-out-input">
														<label>Email</label>
														<input
															type="email"
															name="email"
															value={state?.email}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-dv-input check-out-input">
														<label>Address</label>
														<input
															type="text"
															name="address"
															value={state?.address}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-dv-input check-out-input">
														<label>Suite, Apartment, etc </label>
														<input
															type="text"
															name="suite"
															value={state?.suite}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-dv-input check-out-input">
														<label>City</label>
														<input
															type="text"
															name="city"
															value={state?.city}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-dv-input check-out-input">
														<label>Country/Region</label>
														<input
															type="text"
															name="country"
															value={state?.country}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-dv-input check-out-input">
														<label>State</label>
														<input
															type="text"
															name="states"
															value={state?.states}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-dv-input check-out-input">
														<label>Zip Code</label>
														<input
															type="text"
															name="zip"
															value={state?.zip}
															onChange={HandleChanger}
														/>
													</div>
												</div>
												{/* <div className="col-md-12">
													<div className="check-and-text">
														<div className="form-group">
															<input type="checkbox" id="check" />
															<label for="check">
																Save Shipping Details
															</label>
														</div>
													</div>
												</div> */}
											</div>
										</div>
									</span>
								</label>
								<div className="all-checkout">
									<form>
										<div className="row">
											{/* <div className="col-md-12">
											<div className="form-dv-style-select">
												<label>Shipment Method</label>
												<select
													className="form-select form-select-lg mb-3"
													aria-label=".form-select-lg example"
												>
													<option selected>Select Shipment method</option>
													<option value="1">Select Shipment method</option>
													<option value="2">Select Shipment method</option>
													<option value="3">Select Shipment method</option>
												</select>
											</div>
										</div> */}

											<div className="col-md-12">
												<div className="form-dv-input check-out-input">
													<label>Payment Details</label>
													{/* <input type="text" placeholder="mm/yy       cvc" /> */}
													<MembershipPayment
														memberData={memberData}
														imageUrl={Imageurl}
													/>
												</div>
											</div>

											{/* <div className="col-md-12">
											<div className="banner-button submit-banner-btn login-btn checkout-btn">
												<Link to="/profile" className="all-button">
													add card{" "}
												</Link>
											</div>
										</div> */}
										</div>
									</form>
								</div>
							</div>
						</div>

						<div className="col-md-4">
							<div className="summary-dv">
								<h4>MEMBERSHIP DETAILS</h4>
							</div>

							<div className="all-become-member-div">
								<div
									className="first-dv"
									// style={{
									// 	backgroundImage: `url(${Imageurl}/${memberData?.image})`,
									// }}
								>
									<div className="member-text">
										<h5>{memberData?.title}</h5>
										<h2>
											{/* <span className="dollar">
												<img className="img-fluid" src={dollar} />
											</span> */}
											{currency.format(memberData?.price, {
												code: "USD",
											})}
											<span>/{memberData?.type}</span>
										</h2>
									</div>
								</div>
								<div className="become-member-ul">
									{/* <ul className="member-ul">
										<li>
											{" "}
											<span>
												<img className="img-fluid" src={blackflower} />
											</span>{" "}
											Member Card
										</li>
										<li>
											{" "}
											<span>
												<img className="img-fluid" src={blackflower} />
											</span>{" "}
											Earn Bonus
										</li>
										<li>
											{" "}
											<span>
												<img className="img-fluid" src={blackflower} />
											</span>{" "}
											Lounge Access
										</li>
										<li>
											{" "}
											<span>
												<img className="img-fluid" src={blackflower} />
											</span>{" "}
											Free Smaple
										</li>
										<li>
											{" "}
											<span>
												<img className="img-fluid" src={blackflower} />
											</span>{" "}
											Special Offers
										</li>
									</ul> */}
									<p
									className="paragraf_member"
										dangerouslySetInnerHTML={{	
											__html: memberData?.description,
										}}
									/>
									{/* <div className="banner-button member-btn login-btn">
										<Link className="all-button">
											BUY NOW <img className="img-fluid" src={arrow} />{" "}
										</Link>
									</div> */}
								</div>
							</div>
						</div>
					</div>
					{/* Second Row */}
					<div className="row check-box-row">
						<div className="col-md-6">
							<div className="check-and">
								<div className="form-group">
									<input
										type="checkbox"
										id="bottom-check"
										name="condition"
										value={condition}
										onChange={() => setCondition(!condition)}
									/>
									<label for="bottom-check">
										<span>Proident, sunt in culpa qui officia deserunt mollit anim id
										est laborum.</span>
									</label>
								</div>
							</div>
						</div>

						<div className="col-md-6">
							<div className="banner-button checkout-btns login-btn">
								<button
									className="all-button submitButton"
									onClick={PlaceOrderData}
								>
									{placeOderresponse?.isLoading
										? "Loading...."
										: "CONFIRM PAYMENT"}{" "}
									<img className="img-fluid" src={arrow} />{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Membercheckout;
