import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { arrow, blackflower, dollar } from "../../constant/Index";
import Sidebar from "./Sidebar";
import {
	useCancelmembershipMutation,
	useGetmembershipDataQuery,
} from "../../store/services/Membership";
import swal from "sweetalert";
import Spinner from "../../utils/Loader";

function Loginmembership() {
	const navigate = useNavigate();
	const { data, isLoading } = useGetmembershipDataQuery();
	const [setdata, response] = useCancelmembershipMutation();
	const memberData = data?.response?.data;

	// const HandleDelete = (e) => {
	// 	e.preventDefault();
	// 	swal({
	// 		title: "Are you sure?",
	// 		text: "Once deleted, you will not be able to recover this Membership!",
	// 		icon: "warning",
	// 		buttons: true,
	// 		dangerMode: true,
	// 	}).then((willDelete) => {
	// 		if (willDelete) {
	// 			swal(`Your Membership will be expired on ${memberData?.ends_at} !`, {
	// 				icon: "success",
	// 			});
	// 			setdata();
	// 		}
	// 	});
	// };
	return !isLoading ? (
		<>
			<Header />

			{/* Memebership Section */}
			<section className="member-sec my-profile-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>
						<div className="col-md-9 profile-all">
							<div className="my-profile-head-and-btn">
								<h3>My MEMBERSHIP</h3>
							</div>
							{memberData?.membership?.id ? (
								<div className="row all-border-and-back">
									<div className="col-md-3 for-no-paddng">
										<div className="all-become-member-div all-login-membership">
											<div className="first-dv login-member-dv login-first-dv">
												<div className="member-text login-member-text text-hopp">
													<h5>{memberData?.membership?.title}</h5>
													<h2>
														<span className="dollar">
															<img className="img-fluid" src={dollar} />
														</span>
														{memberData?.membership?.price}
														<span> /mo</span>
													</h2>
												</div>
											</div>
											<div className="become-member-ul">
												<div
													className="paragraf_member"
													dangerouslySetInnerHTML={{
														__html: memberData?.membership?.description,
													}}
												></div>
											</div>
										</div>
									</div>
									<div className="col-md-5">
										<div className="my-profile-head-and-btn login-membership-second-col">
											<h3>PLAN EXPIRES</h3>
										</div>
										<div className="login-member-col">
											<h5>{memberData?.ends_at}</h5>
											{/* <h5>AT 11:35 GMT +5</h5> */}
										</div>

										<div className="login-memebr-btn-all">
											{/* <div className="banner-button member-btn-login login-btn">
											<button
												onClick={HandleDelete}
												className="all-button submitButton"
											>
												CANCEL MEMBERSHIP{" "}
												<img className="img-fluid" src={arrow} />{" "}
											</button>
										</div> */}

											<div className="banner-button member-btn-login login-btn for-no-margin btn-TEXTHANDLE">
												<button
													className={`${
														memberData?.ends_at
															? `all-button submitButton Membershipdata`
															: `all-button submitButton`
													}`}
													onClick={() => navigate("/membership")}
												>
													RENEW MEMBERSHIP{" "}
													<img className="img-fluid arrow-images" src={arrow} />{" "}
												</button>
											</div>
										</div>
									</div>
								</div>
							) : (
								<h3 className="orderCard">No Membership Found</h3>
							)}
						</div>
					</div>
				</div>
			</section>
			<Threelinks />

			<Footer />
		</>
	) : (
		<Spinner />
	);
}

export default Loginmembership;
