import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { capture } from "../../constant/Index";
import { useAuthRegisterMutation } from "../../store/services/AuthServices";

function Register() {
	const navigate = useNavigate();
	const [fname, setFname] = useState();
	const [lname, setLname] = useState();
	const [email, setEmail] = useState();
	const [phone, setPhone] = useState();
	const [password, setPassword] = useState();
	const [cpassword, setCpassword] = useState();
	const [registerData, response] = useAuthRegisterMutation();

	const handleSubmit = (e) => {
		e.preventDefault();
		const fromData = new FormData();
		fromData.append("f_name", fname);
		fromData.append("l_name", lname);
		fromData.append("email", email);
		fromData.append("password", password);
		fromData.append("confirm_password", cpassword);
		fromData.append("phone", phone);
		registerData(fromData);
	};

	useEffect(() => {
		if (response.isError && response?.error?.data?.errors) {
			if (response?.error?.data?.message === "Internal Error!") {
				toast.error(response?.error?.data?.message);
			} else {
				for (let key in response?.error?.data?.errors) {
					if (response?.error?.data?.errors.hasOwnProperty(key)) {
						toast.error(response?.error?.data?.errors[key][0]);
					}
				}
			}
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			setFname("");
			setLname("");
			setEmail("");
			setPhone("");
			setPassword("");
			setCpassword("");
			toast(response?.data?.message);
			navigate("/login");
		}
	}, [response.isSuccess]);
	return (
		<>
			<section className="login-sec">
				<div className="row">
					<div className="col-md-7">
						<div className="login-form-all register-form-all">
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="login-head register-head">
										<h2>Register</h2>
										<p>
											Welcome Back to <span>orderzaza</span> <br />
											The Best place to enhance your look{" "}
										</p>
										<p className="login-para">
											Already a Member ? <Link to="/login">Login</Link>
										</p>
									</div>
									<div className="col-md-6">
										<div className="form-dv-input">
											<input
												type="text"
												value={fname}
												onChange={(e) => setFname(e.target.value)}
												placeholder="First Name"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-dv-input">
											<input
												type="text"
												value={lname}
												onChange={(e) => setLname(e.target.value)}
												placeholder="Last Name"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-dv-input">
											<input
												type="email"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												placeholder="Email Address"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-dv-input">
											<input
												type="text"
												value={phone}
												onChange={(e) => setPhone(e.target.value)}
												placeholder="Phone Number"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-dv-input">
											<input
												type="password"
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												placeholder="Password"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-dv-input">
											<input
												type="password"
												value={cpassword}
												onChange={(e) => setCpassword(e.target.value)}
												placeholder="Confirm Password"
											/>
										</div>
									</div>

									{/* <div className="col-md-12">
                    <div className="remeber-and-forgot new">
                      <div className="form-group">
                        <input type="checkbox" id="html" />
                        <label htmlFor="html">Remeber me</label>
                      </div>

                      <div className="form-group">
                        <Link>Forgot Password ?</Link>
                      </div>
                    </div>
                  </div> */}

									<div className="col-md-6">
										<div className="banner-button submit-banner-btn login-btn">
											<button type="submit" className="all-button submitButton">
												{response?.isLoading ? "Loading..." : "REGISTER"}
											</button>
										</div>
									</div>
								</div>
							</form>
							<div className="follow-us-on">
								<p>
									By Creating Account you are agree to our
									<span>
										<Link to="/terms-condition"> Terms & conditions</Link>
									</span>
								</p>
							</div>
						</div>
					</div>

					<div className="col-md-5">
						<div className="login-form-img">
							<img className="img-fluid" src={capture} />
							<div className="login-form-text">
								<div className="about-banner-text login-all-text">
									<h5>SMOKE PIPE EVERYDAY</h5>
									<h2>orderzaza Smoke Shop.</h2>
									<p>
										Hello, and thank you for visiting orderzaza, the web’s
										premier vendor of premium cannabis pipes, bowls, and
										accessories. We have several different options for both
										casual and devoted customers alike. When you work with us,
										you’ll be dealing with a team of people who care deeply
										about satisfying your needs and giving you the best possible
										customer service. In addition to our retail selection, we
										also have a 420 smoke lounge where you can kick back, relax,
										and enjoy your new purchases.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ========================= */}
		</>
	);
}

export default Register;
