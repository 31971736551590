import { createSlice } from "@reduxjs/toolkit";
const customerToken = JSON.parse(localStorage.getItem("userData"));

const MembershipReducer = createSlice({
	name: "MembershipReducer",
	initialState: {
		memberData: {},
		cardNonce: "",
		memberhipCardNonce: "",
		coupondiscount: "",
	},
	reducers: {
		setmemberData: (state, action) => {
			state.memberData = action?.payload;
		},
		clearMemberData: (state) => {
			state.memberData = {};
		},
		cardNoneData: (state, action) => {
			state.cardNonce = action?.payload;
		},
		clearCardNonce: (state) => {
			state.cardNonce = "";
		},
		membercardNoneData: (state, action) => {
			state.memberhipCardNonce = action?.payload;
		},
		memberclearCardNonce: (state) => {
			state.memberhipCardNonce = "";
		},
		couponDiscount: (state, action) => {
			state.coupondiscount = action?.payload;
		},
		clearCouponDiscount: (state) => {
			state.coupondiscount = "";
		},
	},
});

export const {
	setmemberData,
	clearMemberData,
	cardNoneData,
	membercardNoneData,
	memberclearCardNonce,
	clearCardNonce,
	couponDiscount,
	clearCouponDiscount,
} = MembershipReducer.actions;
export default MembershipReducer.reducer;
