import React from "react";
import { useSocialContentQuery } from "../store/services/SocialService";

function Threelinks() {
	const { data, isLoading } = useSocialContentQuery();
	let socialLinks = data?.response?.data;
	return (
		<>
			<div className="three-links">
				{socialLinks?.map((data) => {
					return (
						<a
							key={data?.id}
							style={{ textTransform: "capitalize" }}
							target="_blank"
							href={data?.link}
						>
							{data?.name}
						</a>
					);
				})}
			</div>
		</>
	);
}

export default Threelinks;
