import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { arrow } from "../../constant/Index";
import Sidebar from "./Sidebar";
import {
	useUsereditInfoMutation,
	useUsermeInfoQuery,
} from "../../store/services/AuthServices";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

function Editprofile() {
	const navigate = useNavigate();
	const { data, isLoading } = useUsermeInfoQuery();
	const [editData, response] = useUsereditInfoMutation();
	const ProfileData = data?.response?.data;
	const [state, setState] = useState({
		f_name: "",
		l_name: "",
		phone: "",
		street_address: "",
	});

	const HandleChanger = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		setState({
			email: ProfileData?.email,
			f_name: ProfileData?.f_name,
			l_name: ProfileData?.l_name,
			phone: ProfileData?.phone,
			street_address: ProfileData?.street_address,
		});
	}, []);

	const HandleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("email", state?.email);
		formData.append("f_name", state?.f_name);
		formData.append("l_name", state?.l_name);
		formData.append("phone", state?.phone);
		formData.append("street_address", state?.street_address);
		editData(formData);
	};

	useEffect(() => {
		if (response?.isSuccess) {
			toast(response?.data?.message);
			navigate("/profile");
		}
	}, [response.isSuccess]);

	// useEffect(() => {
	// 	if (response?.isError) {
	// 		toast.error(response?.error?.data?.errors[0]?.message);
	// 	}
	// }, [response?.isError]);
	useEffect(() => {
		if (response?.isError) {
			toast.error(response?.error?.data?.errors?.[0]);
		}
	}, [response?.error?.data]);

	return (
		<>
			<Header />
			{/* Edit profile section */}
			<section className="edit-profile-sec my-profile-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>
						<div className="col-md-9 profile-all">
							<div className="edit-profile-form">
								<form>
									<div className="row">
										<div className="col-md-12">
											<div className="my-profile-head-and-btn">
												<h3>My Profile</h3>
												<div className="banner-button login-btn profile-edit-btn">
													<button
														onClick={HandleSubmit}
														className="all-button submitButton"
													>
														{response?.isLoading
															? "Loading..."
															: "SAVE PROFILE"}
														<img className="img-fluid" src={arrow} />{" "}
													</button>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="edit-profile-input">
												<label>First Name </label>
												<input
													type="text"
													placeholder="Enter first name"
													name="f_name"
													value={state?.f_name}
													maxlength="15"
													onChange={HandleChanger}
												/>
											</div>
										</div>

										<div className="col-md-6">
											<div className="edit-profile-input">
												<label>Last Name</label>
												<input
													type="text"
													placeholder="Enter last name"
													name="l_name"
													maxlength="15"
													value={state?.l_name}
													onChange={HandleChanger}
												/>
											</div>
										</div>

										<div className="col-md-6">
											<div className="edit-profile-input">
												<label>Phone Number</label>
												<input
													type="number"
													placeholder="Enter phone number"
													name="phone"
													maxlength="12"
													value={state?.phone}
													onChange={HandleChanger}
												/>
											</div>
										</div>

										<div className="col-md-6">
											<div className="edit-profile-input">
												<label>Primary Email</label>
												<input
													disabled
													type="email"
													placeholder="Enter email"
													name="email"
													value={state?.email}
													onChange={HandleChanger}
												/>
											</div>
										</div>

										<div className="col-md-12">
											<div className="edit-profile-input">
												<label>Address </label>
												<input
													type="text"
													placeholder="Enter Address"
													name="street_address"
													value={state?.street_address}
													onChange={HandleChanger}
												/>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<Threelinks />
			</section>
		</>
	);
}

export default Editprofile;
