import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Threelinks from "../../components/Threelinks";
import { arrow, myorderview } from "../../constant/Index";
import Sidebar from "./Sidebar";
import { useGetSingleOderApisQuery } from "../../store/services/PlaceOrderService";
import currency from "currency-formatter";

function Myorderviewdetail() {
	const location = useLocation();
	const { id } = useParams();
	const { data, isLoading } = useGetSingleOderApisQuery(id);
	const MyOrder = data?.response?.data?.products;
	return (
		<>
			<Header />
			{/* My order view detail */}
			<section className="my-order-view-detail-sec my-profile-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>
						<div className="col-md-9 profile-all">
							<div className="row">
								<div className="col-md-12">
									<div className="my-profile-head-and-btn">
										<h3>My orders</h3>
									</div>
								</div>
							</div>

							<div className="my-orders-view">
								<div className="row">
									<div className="col-md-12">
										<div className="order-views">
											<h3>PRODUCT DETAILS</h3>
											{location?.state?.details?.map((item, index) => {
												return (
													<>
														<div className="dv-for-flex">
															<div className="two-stuff">
																<div className="stuff-img">
																	<img
																		className="img-fluid thumnail"
																		src={item?.product?.thumbnail}
																	/>
																</div>
																<div className="for-dv-text">
																	<h5>{item?.product?.name}</h5>
																	<p>
																		({item?.qty}x) {item?.variant}
																	</p>
																</div>
															</div>
															<div className="for-price">
																<h4>
																	{currency.format(item?.price, {
																		code: "USD",
																	})}
																</h4>
															</div>
														</div>
													</>
												);
											})}
										</div>

										<div className="order-views order-views-bottom">
											<h3>ORDER DETAILS</h3>
											{location?.state?.discount_amount > 0 && (
												<div className="dv-for-flex">
													<div className="two-stuff">
														<div className="for-dv-text text-font-chnage">
															<h5>Coupon Code :</h5>
														</div>
													</div>
													<div className="for-price">
														<h4>
															{currency.format(
																location?.state?.discount_amount,
																{
																	code: "USD",
																},
															)}
														</h4>
													</div>
												</div>
											)}

											<div className="dv-for-flex">
												<div className="two-stuff">
													<div className="for-dv-text text-font-chnage">
														<h5>Total :</h5>
													</div>
												</div>
												<div className="for-price">
													<h4>
														{currency.format(location?.state?.order_amount, {
															code: "USD",
														})}
													</h4>
												</div>
											</div>

											<div className="dv-for-flex">
												<div className="two-stuff">
													<div className="for-dv-text text-font-chnage">
														<h5>Order Status : </h5>
													</div>
												</div>
												<div className="for-price">
													<h4 style={{ textTransform: "capitalize" }}>
														{location?.state?.order_status}
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Threelinks />
			</section>

			<Footer />
		</>
	);
}

export default Myorderviewdetail;
