import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Url, ImagesUrlEndPoint, ConfigEndpoint } from "../../Type/BaseUrl";

const ImageUrlService = createApi({
	reducerPath: "image-urls",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
		prepareHeaders: (headers, { getState }) => {
			headers.set("Access-Control-Allow-Origin", "*");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			imageContent: builder.query({
				query: () => {
					return {
						url: ImagesUrlEndPoint,
						method: "Get",
					};
				},
			}),
			coreimageContent: builder.query({
				query: () => {
					return {
						url: ConfigEndpoint,
						method: "Get",
					};
				},
			}),
		};
	},
});

export const { useImageContentQuery, useCoreimageContentQuery } =
	ImageUrlService;
export default ImageUrlService;
